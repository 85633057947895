import { PresentationUploader } from '@components/file_uploader/presentation_uploader';
import { FormAgentsDropDown } from '@components/form_agents_drop_down';
import { FormInput } from '@components/form_input';
import { stripHtmlTags } from '@utils/helpers';
import { subTitles } from '@utils/mockData';

export const PropertyDescriptionBox = ({
  property,
  propertyState,
  onPropertyChanged,
  updatePropertyPhoto,
  updateProperty,
}: PropertyDescriptionBoxProps) => {
  const handleFileUpload = (file: File | File[]) => {
    updatePropertyPhoto({ presentation: file, id: property.id });
  };

  const onRemovePresentation = () => {
    updateProperty({ presentation: null, id: property.id });
  };

  return (
    <div className="add-property-filter__description-box">
      <div className="add-property-filter__description-data">
        <div className="add-property-filter__data-input">
          <FormInput
            type="text"
            label="Title"
            placeholder={'Title'}
            name="title"
            value={propertyState?.title || ''}
            onValueChanged={onPropertyChanged}
          />
          <FormInput
            type="text"
            // label="virtualTourUrl"
            placeholder="Link to Video Tour"
            name="virtualTourUrl"
            value={propertyState?.virtualTourUrl || ''}
            onValueChanged={onPropertyChanged}
          />
        </div>
        {propertyState.status === 5 && (
          <div className="add-property-filter__sub-title-box">
            <FormAgentsDropDown
              title="Status title"
              options={subTitles}
              defaultValue={propertyState.subtitle}
              onChange={(selectedOption: DropDownItem) =>
                onPropertyChanged('subtitle', `${selectedOption.id}`)
              }
            />
            {propertyState.subtitle == 3 && (
              <FormInput
                label="Sale price"
                name="salePrice"
                placeholder={'Sale price'}
                type="number"
                onValueChanged={onPropertyChanged}
                value={propertyState.salePrice || ''}
              />
            )}
          </div>
        )}
        <div className="add-property-filter__data-input">
          <FormInput
            extraClass="add-property-filter__input-img"
            type="number"
            label="Price"
            name="price"
            placeholder={'Price'}
            value={propertyState.price || ''}
            onValueChanged={onPropertyChanged}
            icon={require('@assets/svg/icons/euro.svg').default}
          />

          <FormInput
            type="text"
            label="Address"
            name="address"
            placeholder={'Address'}
            value={propertyState?.address || ''}
            onValueChanged={onPropertyChanged}
          />
        </div>
        <div className="add-property-filter__presentation">
          <span>Presentation</span>
          <div className="add-property-filter__presentation-icon">
            {property.presentation && (
              <img
                onClick={() => window.open(`${property.presentation}`)}
                src={require('@assets/svg/icons/eye.svg').default}
                alt="eye"
              />
            )}
            <PresentationUploader onFileUpload={handleFileUpload}>
              <img
                src={require('@assets/svg/icons/download.svg').default}
                alt="download"
              />
            </PresentationUploader>
            {property.presentation && (
              <img
                onClick={onRemovePresentation}
                src={
                  require('@assets/svg/icons/delete_presentation.svg').default
                }
                alt="delete_presentation"
              />
            )}
          </div>
        </div>
      </div>
      <div className="add-property-filter__description">
        <div className="add-property-filter__description-ext">
          <span>{`${
            property?.isExternal ? 'EXT_' : 'ROSO_'
          }${property?.id}`}</span>
        </div>
        <div className="add-property-filter__description-property">
          <h1>Description</h1>
          <textarea
            value={stripHtmlTags(propertyState?.descriptions?.['en-US'])}
            onChange={(e) => onPropertyChanged('descriptions', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
