export function PhotoDataButton({
  label,
  icon,
  onClick,
}: PhotoDataButtonProps): JSX.Element {
  return (
    <div onClick={onClick} className="data-photo-button">
      <span>{label}</span>
      <img src={icon} alt="" />
    </div>
  );
}
