import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { showToast } from '@components/toast';
import { MessageType } from '@project/enums';
import { ManagersActions } from '@store/items/managers';
import { TranslationsKeys } from '@translations/translations_keys';
import { tryAuthToTelegram } from '@utils/try_auth_to_telegram';

const mapDispatch = {
  requestSignIn: (payload: TelegramAuthInitialPayload) =>
    ManagersActions.requestSignIn(payload),
};

function Component({ requestSignIn }: SignInPageProps): JSX.Element {
  const { t } = useTranslation();

  const onTelegramAuth = async () => {
    const payload: Nullable<TelegramAuthInitialPayload> =
      await tryAuthToTelegram();

    if (payload) {
      requestSignIn(payload);
      return;
    }

    showToast({
      type: MessageType.ERROR,
      message: 'Try again',
    });
  };

  return (
    <section className="sign-in">
      <div className="sign-in__button" onClick={onTelegramAuth}>
        <img
          src={require('../assets/svg/icons/sign_in_telegram.svg').default}
          alt="telegram"
        />
        <span>{t(TranslationsKeys.formsButtonsSignIn)}</span>
      </div>
    </section>
  );
}

export const SignInPageConnector = connect(null, mapDispatch);

export const SignInPage = SignInPageConnector(Component);
