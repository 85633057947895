import { FileUploader } from '@components/file_uploader/file_uploader';

export const FileUploadersSection = ({
  medias,
  handleFileUpload,
}: FileUploadersProps) => {
  return (
    <>
      {medias.length < 1 ? (
        <FileUploader
          multi
          onFileUpload={handleFileUpload}
          children={
            <div className="add-property-filter__upload-item">
              <div className="add-property-filter__upload-box">
                <div className="add-property-filter__upload-item-favorite">
                  <img
                    src={require('@assets/svg/icons/crone.svg').default}
                    alt="crone"
                  />
                </div>
                <img
                  src={require('@assets/svg/icons/upload_black.svg').default}
                  alt="upload_black"
                />
                <span className="add-property-filter__upload-title">
                  Upload image
                </span>
              </div>
            </div>
          }
        />
      ) : (
        <div className="add-property-filter__main-photo-box">
          <span>
            <img
              src={require('@assets/svg/icons/crone.svg').default}
              alt="crone"
            />
          </span>
          <img src={medias[0]?.url} alt="mainPhoto" />
        </div>
      )}
    </>
  );
};
