import dayjs from 'dayjs';

export function UserInfo({ widget, partner }: UserInfoProps): JSX.Element {
  return (
    <div className="person-data">
      <div className="person-data__photo">
        <img
          src={
            partner?.avatar || require('@assets/svg/icons/profile.svg').default
          }
          alt="profile"
        />
      </div>
      <div className="person-data__details">
        <span className="name">{partner?.fullName}</span>
        {partner?.birthday && (
          <span className="birthday">{`🎉 ${dayjs(partner.birthday).format(
            'D MMM',
          )}`}</span>
        )}
      </div>
      {widget
        ? widget
        : partner?.company && (
            <div className="person-data__audocat">
              <img
                src={require('@assets/svg/icons/building.svg').default}
                alt="building"
              />
              <span>{partner?.company}</span>
            </div>
          )}
    </div>
  );
}
