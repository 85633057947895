import { ReduxActionType } from '@project/enums';

const initialState: StatsState = {
  customersStats: null,
};

export function statsReducer(
  state: StatsState = initialState,
  action: DispatchWithPayload<CustomerTotalStats>,
): StatsState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.STATS_SET_CUSTOMERS_TOTAL:
      return {
        ...state,
        customersStats: {
          ...(action.payload as CustomerTotalStats),
        },
      };

    case ReduxActionType.STATS_RESET_CUSTOMERS_TOTAL:
      return {
        ...state,
        customersStats: null,
      };
  }
}
export class StatsActions {
  public static requestCustomerTotalStats(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.STATS_REQUEST_CUSTOMERS_TOTAL,
    };
  }

  public static setCustomersStats(
    payload: CustomerTotalStats,
  ): DispatchWithPayload<CustomerTotalStats> {
    return {
      type: ReduxActionType.STATS_SET_CUSTOMERS_TOTAL,
      payload,
    };
  }

  public static resetCustomersStats(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.STATS_RESET_CUSTOMERS_TOTAL,
    };
  }
}
