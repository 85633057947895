export function AnalyticsButton({
  label,
  icon,
  onTap,
}: AnalyticsButtonProps): JSX.Element {
  return (
    <div className="analytics-button" onClick={onTap}>
      <img src={icon} alt="" />
      <span>{label}</span>
    </div>
  );
}
