export function PartnerCardSocial({
  partner,
  removePartner,
}: PartnerCardSocialProps): JSX.Element {
  return (
    <div className="data-item__person-social-data">
      {partner?.telegramUserName && (
        <a
          href={`tg://resolve?domain=${partner.telegramUserName}`}
          target="_blank"
        >
          <img
            src={require('@assets/svg/icons/telegram.svg').default}
            alt="telegram"
          />
        </a>
      )}
      {partner?.whatsAppNumber && (
        <a href={`https://wa.me/${partner.whatsAppNumber}`} target="_blank">
          <img
            src={require('@assets/svg/icons/whatsapp.svg').default}
            alt="whatsapp"
          />
        </a>
      )}
      {partner?.phoneNumber && (
        <a href={`tel:${partner.phoneNumber}`} target="_blank">
          <img
            src={require('@assets/svg/icons/network.svg').default}
            alt="network"
          />
        </a>
      )}
      <a
        href={`mailto:${partner?.email}?subject=Subject&body=Body`}
        target="_blank"
      >
        <img src={require('@assets/svg/icons/sent.svg').default} alt="sent" />
      </a>
      {partner && (
        <a onClick={() => removePartner({ id: partner.id })}>
          <img
            src={require('@assets/svg/icons/delete.svg').default}
            alt="delete"
          />
        </a>
      )}
    </div>
  );
}
