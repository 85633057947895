import { put, call } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { LocalStorageKey, MessageType } from '@project/enums';
import { AppActions } from '@store/items/app';
import { ManagersActions } from '@store/items/managers';

export function* signInManagerWorker(
  action: DispatchWithPayload<TelegramAuthInitialPayload>,
) {
  yield put(AppActions.lockUI());

  try {
    const credentials: ManagerCredentials | ApiMessage = yield call(
      ApiRequests.signInManager,
      action.payload,
    );

    if (!(credentials as ManagerCredentials).token) {
      throw (credentials as ApiMessage).message;
    }

    yield put(ManagersActions.setData(credentials as ManagerCredentials));

    localStorage.setItem(
      LocalStorageKey.CREDENTIALS,
      JSON.stringify(credentials),
    );
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });
  }

  yield put(AppActions.unlockUI());
}

export function* logoutManagerWorker() {
  localStorage.clear();
  yield put(ManagersActions.resetData());
}
