import { useEffect, useState } from 'react';

import { PropertyType } from '@project/enums';
import { propertyOptions } from '@utils/mockData';

export function PropertyFilterType({
  status,
  onPropertyChanged,
}: {
  status?: number | null;
  onPropertyChanged?: (name: string, value: any) => void;
}): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);
  const [statusState, setStatusState] = useState<PropertyType | ''>('');

  useEffect(() => {
    const initialStatus =
      propertyOptions.find((option) => option.id === status)?.type || '';
    setStatusState(initialStatus);
  }, [status]);

  const handleOptionClick = (option: StatusItem) => {
    if (option.disabled) return; // Prevent selecting disabled options
    setStatusState(option.type);
    onPropertyChanged && onPropertyChanged('status', option.id);
    setIsOpened(false);
  };

  return (
    <div className="property-filter">
      <div
        className={`property-filter__item ${statusState}`}
        onClick={() => setIsOpened(!isOpened)}
      >
        <span>
          {statusState
            ? propertyOptions.find((option) => option.type === statusState)
                ?.label
            : 'Select status'}
        </span>
        <img
          src={require('../assets/svg/icons/arrow_down_white.svg').default}
          alt="arrow_down_white"
          className={`${isOpened ? 'opened' : ''}`}
        />
      </div>
      {isOpened && (
        <div className={`property-filter__layout ${isOpened ? 'opened' : ''}`}>
          {propertyOptions.map((option) => (
            <span
              key={option.type}
              className={`${option.type} ${option.disabled ? 'disabled' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
