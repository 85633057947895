export function AnalyticsItem({
  label,
  widget,
  isSubscribe,
  customersStats,
}: AnalyticsItemProps): JSX.Element {
  return (
    <div className={`analytics-item  ${isSubscribe ? 'subscribe' : ''}`}>
      <span>{label}</span>
      <div className="analytics-item__description">
        {widget}
        {isSubscribe ? (
          <div className="analytics-item__data">
            <div className="analytics-item__data-percent">
              <span>{customersStats?.newsletters.allowed.inPercent}%</span>/
              <span> {customersStats?.newsletters.denied.inPercent}%</span>
            </div>
            <div className="analytics-item__data-ul">
              <div className="analytics-item__data-ul-row">
                <div className="analytics-item__data-ul-circle"></div>
                <span>Allowed</span>
              </div>
              <span>{customersStats?.newsletters.allowed.inAmount} users</span>
            </div>
            <div className="analytics-item__data-ul">
              <div className="analytics-item__data-ul-row">
                <div className="analytics-item__data-ul-circle"></div>
                <span>Denied</span>
              </div>
              <span>{customersStats?.newsletters.denied.inAmount} users</span>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
