export function EyeIcon({ extraClass }: EyeIconProps): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={extraClass}
    >
      <path
        // eslint-disable-next-line max-len
        d="M12 18C17.5228 18 22 12 22 12C22 12 17.5228 6 12 6C6.47715 6 2 12 2 12C2 12 6.47715 18 12 18Z"
        stroke="#403B37"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"
        stroke="#403B37"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
