import { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { ButtonIcon } from '@components/button_icon';
import { FormInput } from '@components/form_input';
import { PropertyAction } from '@store/items/property';

const mapState = ({ property }: RootState) => ({
  property: property.property,
});

const mapDispatch = {
  createNewProperty: PropertyAction.createProperty,
};

export function Component({
  createNewProperty,
}: AddNewPropertyPageProps): JSX.Element {
  const navigate = useNavigate();

  const [newProperty, setNewProperty] = useState({
    title: '',
  });

  const onSubmit = () => {
    createNewProperty({ newProperty, navigate });
  };

  const onCreatePropertyChanged = (name: string, value: string) => {
    setNewProperty((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <>
      <div className="create-property-back">
        <NavLink to="/properties" end>
          <img
            src={require('../assets/svg/icons/arrow-back.svg').default}
            alt="arrow_back"
          />
        </NavLink>
      </div>
      <div className="create-property-box">
        <div className="create-property-box__title">
          Create Title for New Property
        </div>
        <FormInput
          type="text"
          label="Title"
          name="title"
          placeholder="Title for new property"
          value={newProperty.title}
          onValueChanged={onCreatePropertyChanged}
        />
        <ButtonIcon
          label={'Create'}
          extraClasses="default-outline-button"
          onClick={() => onSubmit()}
        />
      </div>
    </>
  );
}

export const AddNewPropertyPageConnector = connect(mapState, mapDispatch);

export const AddNewPropertyPage = AddNewPropertyPageConnector(Component);
