import { ReduxActionType } from '@project/enums';

const initialState: PropertiesState = {
  properties: [],
  isLoading: false,
};

export function propertiesReducer(
  state: PropertiesState = initialState,
  action: DispatchWithPayload<Property[]>,
): PropertiesState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.PROPERTIES_REQUEST_GET_ALL_PROPERTIES:
      return {
        ...state,
        isLoading: true,
      };

    case ReduxActionType.PROPERTIES_SET_DATA:
      return {
        ...state,
        properties: action.payload as Property[],
        isLoading: false,
      };
    case ReduxActionType.PROPERTIES_UPDATE_PROPERTY:
      {
        const updatedProperty = action.payload as Partial<Property>;
        const updatedIndex = state.properties.findIndex(
          (property) => property.id === updatedProperty.id,
        );

        if (updatedIndex !== -1) {
          const currentProperty = state.properties[updatedIndex];
          const updatedProperties = [...state.properties];
          updatedProperties[updatedIndex] = {
            ...currentProperty,
            ...updatedProperty,
          };
          return {
            ...state,
            properties: updatedProperties,
          };
        }
      }
      return state;

    case ReduxActionType.PROPERTIES_UPDATE_PROPERTY_SUCCESS:
      return {
        ...state,
        properties: state.properties.map((property) =>
          /*@ts-ignore*/
          property.id === (action.payload as Property).id
            ? action.payload
            : property,
        ) as Property[],
      };
    case ReduxActionType.PROPERTIES_REQUEST_DELETE_PROPERTY:
      return {
        ...state,
        properties: state.properties.filter(
          /*@ts-ignore*/
          (property) => property.id !== (action.payload.id as Property['id']),
        ),
      };
  }
}

export class PropertiesAction {
  public static setProperties(
    payload: Property[],
  ): DispatchWithPayload<Property[]> {
    return {
      type: ReduxActionType.PROPERTIES_SET_DATA,
      payload,
    };
  }
  public static getProperties(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.PROPERTIES_REQUEST_GET_ALL_PROPERTIES,
    };
  }
  public static updatePropertyInPropertiesAction(
    payload: Partial<Property>,
  ): DispatchWithPayload<Partial<Property>> {
    return {
      type: ReduxActionType.PROPERTIES_UPDATE_PROPERTY,
      payload,
    };
  }

  public static updatePropertyInPropertiesSuccess(
    payload: Property,
  ): DispatchWithPayload<Property> {
    return {
      type: ReduxActionType.PROPERTIES_UPDATE_PROPERTY_SUCCESS,
      payload,
    };
  }
  public static removePropertyInProperties(
    payload: UpdateProperty,
  ): DispatchWithPayload<UpdateProperty> {
    return {
      type: ReduxActionType.PROPERTIES_REQUEST_DELETE_PROPERTY,
      payload,
    };
  }
  public static resetData(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.MANAGERS_RESET_DATA,
    };
  }
}
