import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Navbar } from '@components/navbar';
import { PartnerAction } from '@store/items/partners';
import { AddDataForm } from 'features/partners/partners_components/add_data_form';
import { DataNotFound } from 'features/partners/partners_components/data_not_found';
import { PartnerCard } from 'features/partners/partners_components/partners_card';

const mapState = ({ partners }: RootState) => ({
  partners: partners.partners,
});

const mapDispatch = {
  getAllPartners: PartnerAction.getPartners,
  createNewPartner: PartnerAction.requestCreateNewPartner,
  removePartner: PartnerAction.removePartner,
  updatePartner: PartnerAction.updatePartner,
  removePartnerAvatar: PartnerAction.removeAvatar,
};

function Component({
  partners,
  getAllPartners,
  createNewPartner,
  removePartner,
  updatePartner,
  removePartnerAvatar,
}: PartnersPageProps): JSX.Element {
  const [newPartner, setNewPartner] = useState({
    fullName: '',
    email: '',
  });
  const [searchTerm, setSearchTerm] = useState({
    search: '',
  });

  const onSubmit = () => {
    createNewPartner(newPartner);
    setNewPartner({
      fullName: '',
      email: '',
    });
  };

  const [filteredPartners, setFilteredPartners] = useState<
    Partner[] | undefined | null
  >([]);

  const havePartners = filteredPartners && filteredPartners?.length < 1;
  useEffect(() => {
    getAllPartners();
  }, []);

  useEffect(() => {
    findUser(searchTerm.search);
  }, [searchTerm, partners]);

  const onSearchChanged = async (name: string, value: string) => {
    setSearchTerm({ ...searchTerm, [name]: value });
  };

  const onCreateNewPartnerChanged = async (name: string, value: string) => {
    setNewPartner({ ...newPartner, [name]: value });
  };

  const findUser = debounce((searchText: string) => {
    const filteredPartners = partners?.filter?.((partner) =>
      partner.fullName.toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilteredPartners(filteredPartners);
  }, 500);

  return (
    <>
      <section className="admin-navbar">
        <Navbar />
      </section>
      <section className="partners">
        <AddDataForm
          onSearchChanged={onSearchChanged}
          searchTerm={searchTerm}
          onCreateNewPartnerChanged={onCreateNewPartnerChanged}
          newPartner={newPartner}
          onSubmit={onSubmit}
        />
        {filteredPartners?.map((partner) => (
          <PartnerCard
            key={partner.id}
            partner={partner}
            removePartner={removePartner}
            updatePartner={updatePartner}
            removePartnerAvatar={removePartnerAvatar}
          />
        ))}
        {havePartners && (
          <DataNotFound
            subTitle={
              havePartners && !!searchTerm.search
                ? `There is no data by your request, try something else`
                : `Here still no one partner was added, let's try`
            }
          />
        )}
      </section>
    </>
  );
}

export const PartnersPageConnector = connect(mapState, mapDispatch);

export const PartnersPage = PartnersPageConnector(Component);
