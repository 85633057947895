import { NavigateFunction } from 'react-router-dom';
import { call, put } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { AppRoutes, MessageType } from '@project/enums';
import { AppActions } from '@store/items/app';
import { PropertiesAction } from '@store/items/properties';
import { PropertyAction } from '@store/items/property';

export function* getPropertyId(action: DispatchWithPayload<ExactPartner>) {
  yield put(AppActions.lockUI());

  try {
    const result: ExactProperty | ApiMessage = yield call(
      ApiRequests.getProperty,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }

    yield put(PropertyAction.setProperty(result as ExactProperty));
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_AVATAR_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(PropertiesAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* updatePropertyById(
  action: DispatchWithPayload<UpdateProperty>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: UpdateProperty | ApiMessage = yield call(
      ApiRequests.updateProperty,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }

    yield put(PropertyAction.updatePropertySuccess(result as UpdateProperty));
    showToast({
      type: MessageType.SUCCESS,
      message: 'Property was updated',
    });
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(PropertyAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* updatePropertyPhotoById(
  action: DispatchWithPayload<{
    presentation?: File;
    images?: File | File[];
    id: number;
  }>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: UpdateProperty | ApiMessage = yield call(
      ApiRequests.updatePropertyPhoto,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }

    yield put(PropertyAction.addPropertyPhotoSuccess(result as UpdateProperty));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(PropertyAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* createNewProperty(
  action: DispatchWithPayload<{
    newProperty: { title: string };
    navigate: NavigateFunction;
  }>,
) {
  yield put(AppActions.lockUI());

  try {
    const newProperty: UpdateProperty | ApiMessage = yield call(
      ApiRequests.createNewPropertyRequest,
      action.payload.newProperty,
    );

    if ((newProperty as ApiMessage).message) {
      throw (newProperty as ApiMessage).message;
    }
    action.payload.navigate(
      `${AppRoutes.EDIT_PROPERTY}/${(newProperty as UpdateProperty).id}`,
    );
  } catch (e) {
    showToast({
      type: MessageType.SUCCESS,
      message: 'Property was created',
    });

    if (e === 'Unauthorized') {
      yield put(PropertyAction.resetData());
    }
  }
  yield put(AppActions.unlockUI());
}

export function* removeProperty(action: DispatchWithPayload<UpdateProperty>) {
  yield put(AppActions.lockUI());

  try {
    const result: UpdateProperty | ApiMessage = yield call(
      ApiRequests.deleteProperty,
      action.payload,
    );
    if ((result as ApiMessage)?.message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PROPERTY_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(PropertiesAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}
