import { useState } from 'react';

export function PropertyEditSwitcher({
  label,
  icon,
  onChange,
  checked,
}: PropertyEditSwitcher): JSX.Element {
  const [isSwitched, setIsSwitched] = useState<boolean | undefined>(checked);

  return (
    <div className="property-edit-switcher">
      <img src={icon} alt="swimming_pool" />
      <span>{label}</span>
      <div
        className={`property-edit-switcher__button ${
          isSwitched ? 'switched' : ''
        }`}
        onClick={() => {
          setIsSwitched(!isSwitched);
          onChange && onChange(!isSwitched);
        }}
      >
        <div className="property-edit-switcher__selector"></div>
      </div>
    </div>
  );
}
