import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const ItemType = 'ITEM';

const DragablePhoto = ({ item, index, moveItem, removeItemById }: any) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(draggedItem: any) {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} key={item.id} className="dragable-photo-container">
      <div className="index-badge">{index + 1}</div>
      <div className="remove-icon">
        <img
          src={require('@assets/svg/icons/remove_red.svg').default}
          alt="remove"
          onClick={() => removeItemById(item.id)}
        />
      </div>
      <img src={item.url} alt={`photo-${index}`} />
    </div>
  );
};

export default DragablePhoto;
