import { PropertyType } from '@project/enums';

export function toPrice(value: number): string {
  const priceData = value.toFixed(0);
  const priceIntegerPart = parseInt(priceData)
    .toLocaleString('en-GB')
    .replace(/,/g, '.');

  return `${priceIntegerPart}`;
}

export const isValidYouTubeUrl = (url: string) => {
  const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
  return regex.test(url);
};

export const getPropertyStatusLabel = (
  status: number,
  price?: number | null,
  currencyCode?: string,
): React.ReactNode => {
  switch (status) {
    case 1:
      return 'Sold';
    case 2:
      return 'Long term';
    case 3:
      return 'Secret';
    case 4:
      return 'Archive';
    case 5:
      return price ? `${toPrice(price)} ${currencyCode}` : 'On the market';
    case 6:
      return 'Private';
    case 7:
      return 'Only for customers';
    case 8:
      return 'Only for agents';
    default:
      return 'Unknown status';
  }
};

export const stripHtmlTags = (str: string) => {
  return str?.replace(/<\/?[^>]+(>|$)|[\/"']/g, '');
};

export const adaptPropertyToSend = (body: UpdateProperty) => {
  return {
    ...body,
    id: body.id,
    address: body.address || null,
    price: body.price || null,
    virtualTourUrl: body.virtualTourUrl || null,
    youtubeUrl: body.youtubeUrl || null,
    buildArea: body.buildArea || null,
    landArea: body.landArea || null,
    bathrooms: body.bathrooms || null,
    bedrooms: body.bedrooms || null,
    yearOfBuild: body.yearOfBuild || null,
  };
};
