import { FormInputType, FormName } from '@project/enums';

export function FormInput({
  label,
  name,
  value,
  onValueChanged,
  error,
  className,
  autoResetError = true,
  placeholder = '',
  type = FormInputType.TEXT,
  widget,
  icon,
  extraClass,
}: FormInputProps): JSX.Element {
  return (
    <div className={`form-input ${extraClass}`}>
      {widget ? widget : <span>{label}</span>}
      <input
        autoComplete="off"
        spellCheck="false"
        type={type}
        disabled={false}
        className={`${error ? 'error shake' : ''}`}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onValueChanged(
            e.currentTarget.name as FormName,
            e.currentTarget.value,
          )
        }
      />
      {icon && <img src={icon} />}
    </div>
  );
}
