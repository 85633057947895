export function SubmitButton({
  disabled,
  onClick,
}: SubmitButtonProps): JSX.Element {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div
      className={`submit-button ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      <img src={require('../assets/svg/icons/plus.svg').default} alt="" />
    </div>
  );
}
