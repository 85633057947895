import { useState } from 'react';

import { Checkbox } from './checkbox';
import { RequestUserInfo } from './request_user_info';

export function RequestItem({
  extraClass,
  label,
}: RequestItemProps): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="requests-item">
      <div className="requests-item__fg">
        <div
          className={`requests-item__mark ${
            isOpened ? 'opened' : 'closed'
          } ${extraClass} `}
          onClick={setIsOpened.bind(null, !isOpened)}
        >
          <span>{label}</span>
        </div>
        <div
          className="requests-item__label"
          onClick={setIsOpened.bind(null, !isOpened)}
        >
          <h1>Architecture and Planning</h1>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.
          </span>
        </div>
        <div className="requests-item__column">
          <div className="requests-item__user-fg">
            <RequestUserInfo />
          </div>
          <div className="requests-item__checkbox">
            <Checkbox />
            <span>Answered</span>
          </div>
        </div>
      </div>
      {/* {isOpened ? (
        <PropertyItem
          widget={<AgencyItem extraClass="request" />}
          widgetAmount={<ActiveUsersIcon />}
        />
      ) : null} */}
    </div>
  );
}
