import { useEffect } from 'react';
import { connect } from 'react-redux';

import { AnalyticsButton } from '@components/analytics_button';
import { AnalyticsItem } from '@components/analytics_item';
import { CircleBar } from '@components/circle_bar';
import { Navbar } from '@components/navbar';
import { AppDetails } from '@core/app_details';
import { StatsActions } from '@store/items/stats';

const mapState = ({ stats }: RootState) => ({
  customersStats: stats.customersStats,
});

const mapDispatch = {
  requestCustomerTotalStats: StatsActions.requestCustomerTotalStats,
};

function Component({
  customersStats,
  requestCustomerTotalStats,
}: AnalyticsPageProps): JSX.Element {
  useEffect(() => {
    requestCustomerTotalStats();
  }, []);

  return (
    <>
      <section className="admin-navbar">
        <Navbar />
      </section>
      <section className="analytics">
        <AnalyticsItem
          label="Newsletters subscription"
          customersStats={customersStats}
          widget={
            customersStats ? (
              <CircleBar
                label="Total"
                widgetTitle={`${
                  customersStats?.newsletters.allowed.inAmount +
                  customersStats?.newsletters.denied.inAmount
                } users`}
                isSubscribe={true}
                v1={customersStats?.newsletters.allowed.inPercent}
                v2={customersStats?.newsletters.denied.inPercent}
              />
            ) : (
              <></>
            )
          }
          isSubscribe={true}
        />
        <AnalyticsItem
          label="Online"
          widget={
            <CircleBar
              label={`${customersStats?.currentOnline ?? 0}`}
              isSubscribe={false}
            />
          }
        />
        <div className="analytics__column">
          <AnalyticsButton
            label="Cloudfare Analytics"
            icon={
              require('../assets/svg/icons/cloudflare_analytics.svg').default
            }
            onTap={() => window.open(AppDetails.cloudflareAnalyticsUrl)}
          />
          <AnalyticsButton
            label="Google Analytics"
            icon={require('../assets/svg/icons/google_analytics.svg').default}
            onTap={() => window.open(AppDetails.googleAnalyticsUrl)}
          />
        </div>
      </section>
    </>
  );
}

export const AnalyticsPageConnector = connect(mapState, mapDispatch);

export const AnalyticsPage = AnalyticsPageConnector(Component);
