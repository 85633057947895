import { DataNotFound } from 'features/partners/partners_components/data_not_found';

import { PropertyWrapper } from './property_wrapper';

export function Properties({
  filteredProperties,
  search,
  updatePropertyById,
  removeProperty,
}: PropertiesProps): JSX.Element {
  const haveProperties = filteredProperties && filteredProperties?.length < 1;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {filteredProperties?.map((property) => (
          <PropertyWrapper
            removeProperty={removeProperty}
            updatePropertyById={updatePropertyById}
            key={property.id}
            property={property}
          />
        ))}
      </div>
      {haveProperties && (
        <DataNotFound
          subTitle={
            haveProperties && !!search
              ? `There is no data by your request, try something else`
              : `Here still no one partner was added, let's try`
          }
        />
      )}
    </>
  );
}
