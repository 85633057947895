import { call, put } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { MessageType } from '@project/enums';
import { AppActions } from '@store/items/app';
import { PropertiesAction } from '@store/items/properties';

export function* getAllProperties() {
  yield put(AppActions.lockUI());

  try {
    const allProperties: Property[] | ApiMessage = yield call(
      ApiRequests.getAllProperties,
    );

    if ((allProperties as ApiMessage)?.message) {
      throw (allProperties as ApiMessage).message;
    }

    yield put(PropertiesAction.setProperties(allProperties as Property[]));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(PropertiesAction.resetData());
    }
  }
  yield put(AppActions.unlockUI());
}

// eslint-disable-next-line max-len
export function* updatePropertyById(
  action: DispatchWithPayload<UpdateProperty>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: UpdateProperty | ApiMessage = yield call(
      ApiRequests.updatePropertyInProperties,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }

    yield put(
      PropertiesAction.updatePropertyInPropertiesSuccess(result as Property),
    );
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(PropertiesAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* removePropertyById(
  action: DispatchWithPayload<UpdateProperty>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: UpdateProperty | ApiMessage = yield call(
      ApiRequests.deleteProperty,
      action.payload,
    );
    if ((result as ApiMessage)?.message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PROPERTY_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(PropertiesAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}
