export const DataNotFound = ({ subTitle }: { subTitle: string }) => {
  return (
    <div className="data-not-found">
      <div className="data-not-found__imageBox">
        <img src={require('@assets/png/dataNotFound.png')} />
      </div>
      <div className="data-not-found__title">Data not found</div>
      <div className="data-not-found__subTitle">{subTitle}</div>
    </div>
  );
};
