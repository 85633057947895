import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN from '@assets/translations/en.json';
import { AvailableTranslationLang } from '@project/enums';
import { getUserLang } from '@utils/get_user_lang';

i18n.use(initReactI18next).init({
  lng: getUserLang(),
  resources: {
    [AvailableTranslationLang.EN]: {
      translation: EN,
    },
  },
  interpolation: {
    escapeValue: false,
  },
});

export const TranslationsModule = i18n;
