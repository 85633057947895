import { SubmitButton } from '@components/submit_button';

import { FormInput } from '../../../components/form_input';

export function AddDataForm({
  switcher,
  dropDown,
  onSearchChanged,
  searchTerm,
  onCreateNewPartnerChanged,
  newPartner,
  onSubmit,
}: AddDataFormProps): JSX.Element {
  return (
    <div className="form-data">
      <div className="form-data__layout">
        <form>
          {/* onSubmit */}
          <FormInput
            type="text"
            label="Full Name"
            // label={t(TranslationsKeys.partnersPageFullName)}
            name="fullName"
            placeholder="Michael Jane"
            value={newPartner?.fullName || ''}
            onValueChanged={(name: string, value: string) => {
              if (onCreateNewPartnerChanged) {
                onCreateNewPartnerChanged(name, value);
              }
            }}
          />
          <FormInput
            type="text"
            label="Email"
            name="email"
            placeholder="michael.jane@gmail.com"
            value={newPartner?.email || ''}
            onValueChanged={(name: string, value: string) => {
              if (onCreateNewPartnerChanged) {
                onCreateNewPartnerChanged(name, value);
              }
            }}
          />
          {switcher ? switcher : null}
          {/* @ts-ignore */}
          <SubmitButton onClick={onSubmit} />
        </form>
      </div>
      {dropDown ? dropDown : null}
      <FormInput
        type="text"
        widget={
          <>
            <img
              src={require('@assets/svg/icons/search.svg').default}
              alt="search"
            />
          </>
        }
        name="search"
        placeholder="Search"
        value={searchTerm?.search || ''}
        onValueChanged={(name: string, value: string) => {
          if (onSearchChanged) {
            onSearchChanged(name, value);
          }
        }}
      />
    </div>
  );
}
