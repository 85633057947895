import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { useRoutes } from '@hooks/routes.hook';
import { ManagerRole, RouterAccessStatus } from '@project/enums';

const mapState = ({ managers }: RootState) => ({
  credentials: managers.credentials,
});

function Component({ credentials }: RouterProps): JSX.Element {
  let currentRouteAccess: RouterAccessStatus = RouterAccessStatus.UNAUTHORIZED;

  if (credentials) {
    switch (credentials.role) {
      default:
        break;

      case ManagerRole.ROOT:
        currentRouteAccess = RouterAccessStatus.ROOT;
        break;
    }
  }

  const currentRoutes = useRoutes(currentRouteAccess);

  return <BrowserRouter>{currentRoutes}</BrowserRouter>;
}

export const RouterConnector = connect(mapState, null);

export const Router = RouterConnector(Component);
