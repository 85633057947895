import { AppDetails } from '@core/app_details';

const AUTH_OPTIONS = {
  bot_id: AppDetails.isDevMode ? 6829327942 : 6981901989,
  request_access: true,
};

export function tryAuthToTelegram(): Promise<
  Nullable<TelegramAuthInitialPayload>
> {
  return new Promise((resolve) => {
    //@ts-ignore
    window.Telegram.Login.auth(
      AUTH_OPTIONS,
      (payload: TelegramAuthInitialPayload | boolean) => {
        if (typeof payload !== 'object') {
          resolve(null);
          return;
        }
        resolve(payload);
      },
    );
  });
}
