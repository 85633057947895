import { NavigateFunction } from 'react-router-dom';

import { ApiEndpoints } from '@core/api_endpoints';
import { AppDetails } from '@core/app_details';
import { FetchRequestType } from '@project/enums';
import { fetchAdvanced } from '@utils/fetch_advanced';
export class ApiRequests {
  public static async signInManager(
    body: TelegramAuthInitialPayload,
  ): Promise<ManagerCredentials | ApiMessage> {
    return fetchAdvanced<ManagerCredentials>({
      url: `${AppDetails.apiHost}${ApiEndpoints.managersSignIn}`,
      method: FetchRequestType.POST,
      body,
    });
  }

  public static async getCustomersStats(): Promise<
    CustomerTotalStats | ApiMessage
  > {
    return fetchAdvanced<CustomerTotalStats>({
      url: `${AppDetails.apiHost}${ApiEndpoints.statsCustomers}`,
      method: FetchRequestType.GET,
    });
  }

  public static async getAllPartners(): Promise<Partner[]> {
    return fetchAdvanced<Partner[]>({
      url: `${AppDetails.apiHost}${ApiEndpoints.partnersAll}`,
      method: FetchRequestType.GET,
    });
  }

  public static async createNewPartner(
    body: CreateNewPartnerDto,
  ): Promise<ApiMessage> {
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}${ApiEndpoints.partnersExact}`,
      method: FetchRequestType.POST,
      body,
    });
  }

  public static async updatedPartner(
    body: UpdateExactPartnerDto,
  ): Promise<ApiMessage> {
    const formData = new FormData();
    for (const key in body) {
      if (Object.hasOwnProperty.call(body, key)) {
        /*@ts-ignore*/
        formData.append(key, body[key]);
      }
    }
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}${ApiEndpoints.partnersExact}`,
      method: FetchRequestType.PATCH,
      body: formData,
    });
  }

  public static async deletePartner(body: ExactPartner): Promise<ApiMessage> {
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}${ApiEndpoints.partnersExact}?id=${body.id}`,
      method: FetchRequestType.DELETE,
    });
  }
  // eslint-disable-next-line max-len
  public static async removePartnerAvatar(
    body: ExactPartner,
  ): Promise<ApiMessage> {
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}${ApiEndpoints.partnersAvatar}?id=${body.id}`,
      method: FetchRequestType.DELETE,
    });
  }

  public static async getAllProperties(): Promise<Property[]> {
    return fetchAdvanced<Property[]>({
      url: `${AppDetails.apiHost}properties/admin/all`,
      method: FetchRequestType.GET,
    });
  }

  public static async updatePropertyInProperties(
    body: UpdateProperty,
  ): Promise<ApiMessage> {
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}${ApiEndpoints.propertiesExact}`,
      method: FetchRequestType.POST,
      body,
    });
  }

  public static async deleteProperty(
    body: UpdateProperty,
  ): Promise<ApiMessage> {
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}${ApiEndpoints.propertiesExact}`,
      method: FetchRequestType.DELETE,
      body,
    });
  }

  public static async getProperty(body: ExactPartner): Promise<Property[]> {
    return fetchAdvanced<Property[]>({
      url: `${AppDetails.apiHost}properties/admin/exact?id=${body.id}`,
      method: FetchRequestType.GET,
    });
  }

  public static async createNewPropertyRequest(body: {
    title: string;
  }): Promise<ApiMessage> {
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}${ApiEndpoints.propertiesExact}`,
      method: FetchRequestType.POST,
      body,
    });
  }

  public static async updateProperty(
    body: UpdateProperty,
  ): Promise<ApiMessage> {
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}properties/exact`,
      method: FetchRequestType.PATCH,
      body,
    });
  }

  public static async updatePropertyPhoto(data: {
    images?: File | File[];
    id: number;
    presentation?: File;
  }): Promise<ApiMessage> {
    const { images, id, presentation } = data;
    const formData = new FormData();

    if (images) {
      if (Array.isArray(images)) {
        images.forEach((file) => {
          formData.append('images', file);
        });
      } else {
        formData.append('images', images);
      }
    }

    if (presentation) {
      formData.append('presentation', presentation);
    }

    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}properties/${id}/upload`,
      method: FetchRequestType.POST,
      body: formData,
    });
  }
}
