import { NavigateFunction } from 'react-router-dom';

import { ReduxActionType } from '@project/enums';

const initialState: PropertyState = {
  property: null,
};

export function propertyReducer(
  state: PropertyState = initialState,
  action: DispatchWithPayload<UpdateProperty>,
): PropertyState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.PROPERTY_SET_DATA:
      return {
        ...state,
        property: action.payload as UpdateProperty,
      };
    case ReduxActionType.PROPERTY_RESET_DATA:
      return {
        ...state,
        property: null,
      };
    case ReduxActionType.CREATE_NEW_PARTNER_SUCCESS:
      return {
        ...state,
        property: action.payload as UpdateProperty,
      };
    case ReduxActionType.PROPERTY_REQUEST_UPDATE:
      return state;

    case ReduxActionType.PROPERTY_REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        property: action.payload as UpdateProperty,
      };

    case ReduxActionType.PROPERTY_REQUEST_ADD_PHOTO:
      return state;

    case ReduxActionType.PROPERTY_REQUEST_ADD_PHOTO_SUCCESS:
      return {
        ...state,
        property: action.payload as UpdateProperty,
      };
    case ReduxActionType.PROPERTY_REQUEST_REMOVE:
      return {
        ...state,
        property: null,
      };
  }
}

export class PropertyAction {
  public static getProperty(
    payload: ExactPartner,
  ): DispatchWithPayload<ExactPartner> {
    return {
      type: ReduxActionType.PROPERTY_REQUEST_GET_PROPERTY,
      payload,
    };
  }
  public static setProperty(
    payload: ExactProperty,
  ): DispatchWithPayload<ExactProperty> {
    return {
      type: ReduxActionType.PROPERTY_SET_DATA,
      payload,
    };
  }
  public static createProperty(payload: {
    newProperty: {
      title: string;
    };
    navigate: NavigateFunction;
  }): DispatchWithPayload<{
    newProperty: {
      title: string;
    };
    navigate: NavigateFunction;
  }> {
    return {
      type: ReduxActionType.PROPERTY_REQUEST_CREATE_NEW_PROPERTY,
      payload,
    };
  }
  public static createPropertySuccess(
    payload: UpdateProperty,
  ): DispatchWithPayload<UpdateProperty> {
    return {
      type: ReduxActionType.PROPERTY_REQUEST_CREATE_NEW_PROPERTY,
      payload,
    };
  }
  public static removeProperty(
    payload: ExactPartner,
  ): DispatchWithPayload<ExactPartner> {
    return {
      type: ReduxActionType.PROPERTY_REQUEST_REMOVE,
      payload,
    };
  }
  public static removePropertySuccess(
    payload: UpdateProperty,
  ): DispatchWithPayload<UpdateProperty> {
    return {
      type: ReduxActionType.PROPERTY_REQUEST_REMOVE_SUCCESS,
      payload,
    };
  }
  public static updateProperty(
    payload: UpdateProperty,
  ): DispatchWithPayload<UpdateProperty> {
    return {
      type: ReduxActionType.PROPERTY_REQUEST_UPDATE,
      payload,
    };
  }
  public static updatePropertySuccess(
    payload: UpdateProperty,
  ): DispatchWithPayload<UpdateProperty> {
    return {
      type: ReduxActionType.PROPERTY_REQUEST_UPDATE_SUCCESS,
      payload,
    };
  }
  public static addPropertyPhoto(payload: {
    images?: File | File[];
    id: number;
    presentation?: File;
  }): DispatchWithPayload<{
    images?: File | File[];
    id: number;
    presentation?: File;
  }> {
    return {
      type: ReduxActionType.PROPERTY_REQUEST_ADD_PHOTO,
      payload,
    };
  }
  public static addPropertyPhotoSuccess(
    payload: UpdateProperty,
  ): DispatchWithPayload<UpdateProperty> {
    return {
      type: ReduxActionType.PROPERTY_REQUEST_ADD_PHOTO_SUCCESS,
      payload,
    };
  }
  public static resetData(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.PROPERTY_RESET_DATA,
    };
  }
}
