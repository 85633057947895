export function CircleBar({
  label,
  isSubscribe,
  widgetTitle,
  v1,
  v2,
}: ProgressBarProps): JSX.Element {
  return (
    <>
      <div className={`progress-bar ${isSubscribe ? 'subscribe' : ''}`}>
        <span>{label}</span>
        <span className="title">{widgetTitle ? widgetTitle : ''}</span>
        <div
          className={`progress-bar__item  ${isSubscribe ? 'subscribe' : ''}`}
          style={
            isSubscribe
              ? {
                  background:
                    `radial-gradient(closest-side, ` +
                    `#f3f3f3 79%, transparent 80% ` +
                    `100%), conic-gradient(#5d97cc ${v1}%,` +
                    ` #cc5d5d ${v2}%, #cc5d5d 0)`,
                }
              : {}
          }
        >
          <progress className="progress" id="html" value="92"></progress>
        </div>
      </div>
    </>
  );
}
