import { useState } from 'react';

import { LabelIcon } from '@components/label_icon';
import { getPropertyStatusLabel } from '@utils/helpers';

export function PropertyItem({ property }: PropertyItemProps): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className={`property-item ${isOpened ? 'opened' : 'closed'}`}>
      <div className="property-item__layout">
        <div className="property-item__img-container">
          {property.status === 1 && (
            <div className="property-item__sold-label">
              <div>SOLD</div>
            </div>
          )}
          <img
            className="property-item__mainPhoto"
            src={
              (property?.images && property?.images[0]) ||
              `${require('@assets/png/background.png')}`
            }
            alt="property"
          />
        </div>
        <div className="property-item__details">
          <div className="property-item__pre-header"></div>
          <div className="property-item__header">
            <h2>{property.title}</h2>
            <span>
              {getPropertyStatusLabel(
                property.status,
                property.price,
                property.currencyCode,
              )}
            </span>
          </div>
          {property.description && (
            <p
              dangerouslySetInnerHTML={{
                __html: property?.description && property?.description['en-US'],
              }}
            ></p>
          )}
          <div className="property-item__info">
            {property.hasSwimmingPool && (
              <LabelIcon
                label="SWIMMING POOL"
                data="YES"
                icon={
                  require('@assets/svg/icons/property_item/swimming_pool.svg')
                    .default
                }
              />
            )}
            {property.landArea && (
              <LabelIcon
                label="LAND AREA"
                data={`${property.landArea} ${property.measurementUnitSymbol}`}
                icon={
                  require('@assets/svg/icons/property_item/land_area.svg')
                    .default
                }
              />
            )}
            {property.buildArea !== 0 && (
              <LabelIcon
                label="BUILD AREA"
                data={`${property.buildArea} ${property.measurementUnitSymbol}`}
                icon={
                  require('@assets/svg/icons/property_item/build_area.svg')
                    .default
                }
              />
            )}
            {property.bedrooms !== 0 && (
              <LabelIcon
                label="BEDROOM"
                data={`${property.bedrooms}`}
                icon={
                  require('@assets/svg/icons/property_item/bedroom.svg').default
                }
              />
            )}
            {property.bathrooms !== 0 && (
              <LabelIcon
                label="BATHROOM"
                data={`${property.bathrooms}`}
                icon={
                  require('@assets/svg/icons/property_item/bathroom.svg')
                    .default
                }
              />
            )}
            {property.hasGarage && (
              <LabelIcon
                label="GARAGE"
                data="YES"
                icon={
                  require('@assets/svg/icons/property_item/garage.svg').default
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
