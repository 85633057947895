export function CertificateItem({
  extraClass,
  label,
  selected,
  handleOnClick,
}: AddNewProperty): JSX.Element {
  return (
    <div
      onClick={handleOnClick}
      className={`certificate-item ${extraClass} ${selected ? 'selected' : ''}`}
    >
      <span>{label}</span>
    </div>
  );
}
