import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Navbar } from '@components/navbar';
import Spinner from '@components/spinner';
import { PropertiesAction } from '@store/items/properties';
import { AddPropertyItem } from 'features/properties/properties_components/add_property_item';
import { Properties } from 'features/properties/properties_components/properties';

type Filters = {
  propertyType: { id: number; name: string } | null;
  location: { id: number; name: string } | null;
  price: { id: number; name: string } | null;
};

const mapState = ({ properties }: RootState) => ({
  properties: properties.properties,
  isLoading: properties.isLoading,
});

const mapDispatch = {
  getAllProperties: PropertiesAction.getProperties,
  updatePropertyById: PropertiesAction.updatePropertyInPropertiesAction,
  removeProperty: PropertiesAction.removePropertyInProperties,
};

function Component({
  properties,
  isLoading,
  getAllProperties,
  updatePropertyById,
  removeProperty,
}: PropertiesPageProps): JSX.Element {
  const [filters, setFilters] = useState({
    propertyType: null,
    location: null,
    price: null,
  });

  const [searchTerm, setSearchTerm] = useState({
    search: '',
  });

  const applyFilters = (
    properties: Property[],
    filters: Filters,
  ): Property[] => {
    return properties.filter((property) => {
      // propertyType id
      if (filters.propertyType && property.typeId !== filters.propertyType.id) {
        return false;
      }

      // location id
      if (filters.location && property.locationId !== filters.location.id) {
        return false;
      }

      // price
      if (filters.price) {
        const priceValue = parseInt(filters.price.name.replace(/[^0-9]/g, ''));
        if (!property.price || property.price <= priceValue) {
          return false;
        }
      }

      return true;
    });
  };

  const handleFilterChange = (
    key: string,
    selectedOption: DropDownItem | null,
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: selectedOption,
    }));
  };

  useEffect(() => {
    setFilteredProperties(applyFilters(properties, filters));
  }, [filters, properties]);

  const [filteredProperties, setFilteredProperties] = useState<
    Property[] | undefined | null
  >([]);

  useEffect(() => {
    findUser(searchTerm.search);
  }, [searchTerm, properties]);

  useEffect(() => {
    getAllProperties();
  }, []);

  const findUser = debounce((searchText: string) => {
    const filteredPartners = properties?.filter?.(
      (property) =>
        property?.title?.toLowerCase().includes(searchText?.toLowerCase()),
    );
    setFilteredProperties(filteredPartners);
  }, 500);

  const onSearchChanged = async (name: string, value: string) => {
    setSearchTerm({ ...searchTerm, [name]: value });
  };

  useEffect(() => {
    getAllProperties();
  }, []);
  return (
    <>
      <section className="admin-navbar">
        <Navbar />
      </section>
      <section className="properties">
        <AddPropertyItem
          handleFilterChange={handleFilterChange}
          onSearchChanged={onSearchChanged}
          searchTerm={searchTerm}
        />
        {!isLoading ? (
          <Properties
            removeProperty={removeProperty}
            updatePropertyById={updatePropertyById}
            filteredProperties={filteredProperties}
            search={searchTerm.search}
          />
        ) : (
          <Spinner />
        )}
      </section>
    </>
  );
}

export const PropertiesPageConnector = connect(mapState, mapDispatch);

export const PropertiesPage = PropertiesPageConnector(Component);
