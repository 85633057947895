import { useState } from 'react';

import { PartnerCardForm } from './partner_card_form';
import { PartnerCardSocial } from './partner_card_social';
import { UserInfo } from './user_info';

export function PartnerCard({
  widget,
  docsWidget,
  preferencesWidget,
  partner,
  removePartner,
  updatePartner,
  removePartnerAvatar,
}: PartnerCardProps): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="data-item">
      <div className={`data-item__layout ${isOpened ? 'open' : ''}`}>
        <img
          src={require('@assets/svg/icons/arrow-down.svg').default}
          alt="arrow-down"
          onClick={() => setIsOpened(!isOpened)}
          className={isOpened ? 'opened' : 'closed'}
        />
        <UserInfo widget={widget} partner={partner} />
        <PartnerCardSocial partner={partner} removePartner={removePartner} />
      </div>
      <PartnerCardForm
        partner={partner}
        isOpened={isOpened}
        docsWidget={docsWidget}
        preferencesWidget={preferencesWidget}
        updatePartner={updatePartner}
        removePartnerAvatar={removePartnerAvatar}
      />
    </div>
  );
}
