import { call, put, select } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { MessageType } from '@project/enums';
import { AppActions } from '@store/items/app';
import { PartnerAction } from '@store/items/partners';

export function* createNewPartner(
  action: DispatchWithPayload<CreateNewPartnerDto>,
) {
  yield put(AppActions.lockUI());

  try {
    const newPartner: Partner | ApiMessage = yield call(
      ApiRequests.createNewPartner,
      action.payload,
    );

    if ((newPartner as ApiMessage).message) {
      throw (newPartner as ApiMessage).message;
    }
    /*@ts-ignore*/
    const partners = yield select(
      (state: RootState): Partner[] => state.partners.partners,
    );
    const newPartners = [newPartner, ...partners];
    yield put(PartnerAction.setPartners(newPartners as Partner[]));
    showToast({
      type: MessageType.SUCCESS,
      message: 'Partner was created',
    });
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: 'An error occurred',
    });

    if (e === 'Unauthorized') {
      yield put(PartnerAction.resetData());
    }
  }
  yield put(AppActions.unlockUI());
}

export function* getAllPartners() {
  yield put(AppActions.lockUI());

  try {
    const allPartners: Partner[] | ApiMessage = yield call(
      ApiRequests.getAllPartners,
    );

    if ((allPartners as ApiMessage).message) {
      throw (allPartners as ApiMessage).message;
    }

    yield put(PartnerAction.setPartners(allPartners as Partner[]));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(PartnerAction.resetData());
    }
  }
  yield put(AppActions.unlockUI());
}

export function* removePartnerById(action: DispatchWithPayload<ExactPartner>) {
  yield put(AppActions.lockUI());

  try {
    const result: ExactPartner | ApiMessage = yield call(
      ApiRequests.deletePartner,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(PartnerAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* updatePartnerById(
  action: DispatchWithPayload<UpdateExactPartnerDto>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: UpdateExactPartnerDto | ApiMessage = yield call(
      ApiRequests.updatedPartner,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }

    yield put(
      PartnerAction.updatePartnerSuccess(result as UpdateExactPartnerDto),
    );
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(PartnerAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* removePartnerAvatarById(
  action: DispatchWithPayload<ExactPartner>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: ExactPartner | ApiMessage = yield call(
      ApiRequests.removePartnerAvatar,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_AVATAR_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(PartnerAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}
