import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '@project/enums';
import { locations, priceFilter, propertiesTypes } from '@utils/mockData';

import { FormAgentsDropDown } from '../../../components/form_agents_drop_down';
import { FormInput } from '../../../components/form_input';
import { SubmitButton } from '../../../components/submit_button';

export function AddPropertyItem({
  onSearchChanged,
  searchTerm,
  handleFilterChange,
}: AddDataFormProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <div className="property-data">
      <FormInput
        extraClass="properties"
        type="text"
        widget={
          <>
            <img
              src={require('@assets/svg/icons/search.svg').default}
              alt="search"
            />
          </>
        }
        name="search"
        placeholder="Search"
        value={searchTerm?.search || ''}
        onValueChanged={(name: string, value: string) => {
          if (onSearchChanged) {
            onSearchChanged(name, value);
          }
        }}
      />
      <FormAgentsDropDown
        title="Property Type"
        icon={require('@assets/svg/icons/type.svg').default}
        options={propertiesTypes}
        onRemove={() =>
          handleFilterChange && handleFilterChange('propertyType', null)
        }
        onChange={(selectedOption) =>
          handleFilterChange &&
          handleFilterChange('propertyType', selectedOption)
        }
      />
      <FormAgentsDropDown
        title="Location"
        icon={require('@assets/svg/icons/location.svg').default}
        options={locations}
        onRemove={() =>
          handleFilterChange && handleFilterChange('location', null)
        }
        onChange={(selectedOption) =>
          handleFilterChange && handleFilterChange('location', selectedOption)
        }
      />
      <FormAgentsDropDown
        title="Price"
        icon={require('@assets/svg/icons/euro.svg').default}
        options={priceFilter}
        onRemove={() => handleFilterChange && handleFilterChange('price', null)}
        onChange={(selectedOption) =>
          handleFilterChange && handleFilterChange('price', selectedOption)
        }
      />
      {/* <NavLink to="/add-new-property" end> */}
      {/*  @ts-ignore */}
      <SubmitButton onClick={() => navigate(AppRoutes.ADD_NEW_PROPERTY)} />
      {/* </NavLink> */}
    </div>
  );
}
