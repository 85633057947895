import { AppDetails } from '@core/app_details';
import { AvailableTranslationLang, LocalStorageKey } from '@project/enums';

export function getUserLang(): AvailableTranslationLang {
  const AVAILABLE_LANGS = [AvailableTranslationLang.EN];

  const userLang = navigator.language.toUpperCase() as AvailableTranslationLang;
  const savedLang = localStorage.getItem(
    LocalStorageKey.USER_LANG,
  ) as Nullable<AvailableTranslationLang>;

  if (savedLang && AVAILABLE_LANGS.includes(savedLang)) {
    const index = AVAILABLE_LANGS.indexOf(savedLang);

    return AVAILABLE_LANGS[index];
  }

  if (AVAILABLE_LANGS.includes(userLang)) {
    const index = AVAILABLE_LANGS.indexOf(userLang);

    return AVAILABLE_LANGS[index];
  }

  return AppDetails.fallbackAppLang;
}
