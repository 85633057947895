import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from '@components/modal';
import { PropertyFilterType } from '@components/property_filter_type';
import { PropertySwitcher } from '@components/property_switcher';
import { AppRoutes } from '@project/enums';
import { PropertyItem } from 'features/properties/properties_components/property_item';

export const PropertyWrapper = ({
  property,
  updatePropertyById,
  removeProperty,
}: PropertyWrapperType) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleRemoveProperty = () => {
    setIsModalOpen(false);
    removeProperty({ id: property.id });
  };

  const [propertyState, setPropertyState] = useState({
    id: property.id,
    status: property.status,
    isVisible: property.isVisible,
  });

  const onPropertyChanged = async (name: string, value: any) => {
    let newValue = value;
    const updatedProperty = { ...propertyState, [name]: newValue };
    setPropertyState(updatedProperty);
    updatePropertyById(updatedProperty);
  };
  return (
    <div key={property?.id} className="properties__layout">
      <PropertyItem type="like" property={property} />
      <div className="properties__settings">
        <div className="properties__settings-panel">
          <div className="properties__edit-panel">
            {property.presentation && (
              <img
                src={
                  require('@assets/svg/icons/upload_presentation.svg').default
                }
                alt="upload_presentation"
                onClick={() => window.open(`${property.presentation}`)}
              />
            )}
            <img
              onClick={() =>
                navigate(`${AppRoutes.EDIT_PROPERTY}/${property?.id}`)
              }
              src={require('@assets/svg/icons/edit.svg').default}
              alt="edit"
            />
            <img
              onClick={() => handleModalToggle()}
              src={require('@assets/svg/icons/delete.svg').default}
              alt="delete"
            />
          </div>
          <PropertyFilterType
            onPropertyChanged={onPropertyChanged}
            status={propertyState?.status}
          />
        </div>
        <PropertySwitcher
          onPropertyChanged={onPropertyChanged}
          property={property}
        />
      </div>
      {isModalOpen && (
        <Modal onClose={handleModalToggle}>
          <p>Are you sure you want to delete this property?</p>
          <div className="modal-buttons">
            <button
              className="modal-button modal-button--confirm"
              onClick={handleRemoveProperty}
            >
              Yes
            </button>
            <button className="modal-button" onClick={handleModalToggle}>
              No
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
