export class ApiEndpoints {
  public static managersSignIn = 'managers/sign-in';

  public static statsCustomers = 'stats/customers';

  public static partnersAll = 'partners/all';
  public static partnersExact = 'partners/exact';
  public static partnersAvatar = 'partners/avatar';

  public static propertiesAll = 'properties/all';
  public static propertiesExact = 'properties/exact';
}
