import { useState } from 'react';

type OptionsType = {
  id: number;
  name: string;
};

export function FormAgentsDropDown({
  icon,
  title,
  options,
  onChange,
  onRemove,
  defaultValue,
}: DropDownDataProps): JSX.Element {
  const defaultOption =
    options.find((option) => option.id === defaultValue) || null;
  const [isOpened, setIsOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionsType | null>(
    defaultOption || null,
  );
  const handleOptionClick = (option: OptionsType) => {
    setSelectedOption(option);
    onChange && onChange(option);
    setIsOpened(false);
  };

  return (
    <div className="agents__drop-down" onClick={() => setIsOpened(!isOpened)}>
      <div className="agents__drop-down-fg">
        <div className="agents__drop-down-icon">
          {icon && <img src={icon} alt="all_agents" />}
          <span>{selectedOption ? selectedOption.name : title} </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {selectedOption && onRemove && (
            <div style={{ display: 'flex' }}>
              <img
                src={require('@assets/svg/icons/remove.svg').default}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove && onRemove();
                  setSelectedOption(null);
                }}
              />
            </div>
          )}
          <img
            src={require('../assets/svg/icons/arrow_down_black.svg').default}
            alt="arrow_down_black"
            className={`${isOpened ? 'opened' : ''}`}
          />
        </div>
      </div>
      {isOpened && (
        <div
          style={{
            maxHeight: '300px',
            position: 'absolute',
            top: '105%',
            minWidth: '100%',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            overflow: 'auto',
            borderRadius: '12px',
            whiteSpace: 'nowrap',
            zIndex: '10',
          }}
        >
          {options.map((option) => (
            <div
              key={option.id}
              onClick={() => handleOptionClick(option)}
              style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
              className={`agents__drop-down-layout ${isOpened ? 'opened' : ''}`}
            >
              <span>{option.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
