export const PrivateImage = ({ image }: { image: string }) => {
  return (
    <div className="private-image-box">
      <img
        style={{ maxHeight: '300px' }}
        src={image || `${require('@assets/png/background.png')}`}
      />
    </div>
  );
};
