import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { SubmitButton } from '@components/submit_button';
import { showToast } from '@components/toast';
import { MessageType, PropertyEnergyCertificate } from '@project/enums';
import {
  adaptPropertyToSend,
  isValidYouTubeUrl,
  stripHtmlTags,
} from '@utils/helpers';
import { PropertyBuildPanel } from 'features/properties/properties_components/property_build_panel';
import { PropertyDescriptionBox } from 'features/properties/properties_components/property_description_box';
import { PropertyEditPanel } from 'features/properties/properties_components/property_edit_panel';
import { PropertyHeader } from 'features/properties/properties_components/property_header';

import { FeaturesWrapper } from './features_wrapper';
import { PrivateImagesWrapper } from './private_images_wrapper';

type mediaProps = {
  id: string;
  url: string;
};

export function EditProperty({
  property,
  updateProperty,
  updatePropertyPhoto,
  removeProperty,
}: EditPropertyProps): JSX.Element {
  const [propertyState, setPropertyState] = useState<UpdateProperty>({
    id: property.id,
    youtubeUrl: property?.youtubeUrl || undefined,
    subtitle: property?.subtitle || undefined, //TODO
    salePrice: property?.salePrice || undefined, //TODO
    virtualTourUrl: property?.virtualTourUrl || undefined,
    title: property?.title || '',
    price: property?.price || undefined,
    yearOfBuild: property?.yearOfBuild || undefined,
    address: property?.address || undefined,
    descriptions: property?.description || undefined,
    // maxAmount: property?.maxPreviewAmount || undefined,
    buildArea: property?.buildArea || undefined,
    landArea: property?.landArea || undefined,
    bathrooms: property?.bathrooms || undefined,
    bedrooms: property?.bedrooms || undefined,
    energyCertificate:
      property?.energyCertificate || PropertyEnergyCertificate.A,
    hasSwimmingPool: property?.hasSwimmingPool || undefined,
    hasGarage: property?.hasGarage || undefined,
    ownership: property?.ownership || undefined,
    isVisible: property?.isVisible || false,
    status: property?.status || undefined,
    locationId: property?.locationId || undefined,
    propertyFeatures: property?.propertyFeatures || [],
    type: property?.type || undefined,
  });

  // console.log(propertyState.subtitle, 'statusTitle');
  // console.log(propertyState.salePrice, 'salePrice');
  const handleChangeMedias = (medias: mediaProps[]) => {
    onPropertyChanged(
      'images',
      medias.map((media) => media.url),
    );
  };

  const onPropertyChanged = async (name: string, value: any) => {
    let newValue = value;

    if (
      [
        'buildArea',
        'landArea',
        'bathrooms',
        'bedrooms',
        'price',
        'yearOfBuild',
      ].includes(name) &&
      value < 0
    ) {
      newValue = 0;
    }

    if (name === 'salePrice' && value === '') {
      newValue = null;
    }
    if (name === 'youtubeUrl' && !isValidYouTubeUrl(value)) {
      showToast({
        type: MessageType.ERROR,
        message: 'Youtube Link is not valid',
      });
      return;
    }
    const updatedProperty = { ...propertyState, [name]: newValue };

    setPropertyState(updatedProperty);
  };

  const onPropertyFeatureChanged = (feature: PropertyFeatureDto) => {
    const newFeatures = propertyState?.propertyFeatures?.map((f) =>
      f.id === feature.id ? feature : f,
    );
    onPropertyChanged('propertyFeatures', newFeatures);
  };

  const onSubmit = (body: UpdateProperty) => {
    if (typeof body.descriptions === 'object') {
      try {
        const cleanedDescription = stripHtmlTags(body.descriptions['en-US']);
        body.descriptions = JSON.stringify(cleanedDescription);
      } catch (error) {
        showToast({
          type: MessageType.ERROR,
          message: 'Something went wrong',
        });
        return;
      }
    }
    updateProperty(adaptPropertyToSend(body));
  };
  return (
    <>
      {propertyState.title && (
        <div
          style={{
            position: 'fixed',
            bottom: '3%',
            right: '3%',
            zIndex: '20',
          }}
        >
          <SubmitButton
            disabled={
              propertyState.subtitle == 3 &&
              (propertyState.salePrice === null ||
                propertyState.salePrice === undefined)
            }
            onClick={() => onSubmit(propertyState)}
          />
        </div>
      )}
      <section className="control-panel">
        <NavLink to="/properties" end>
          <img
            src={require('@assets/svg/icons/arrow-back.svg').default}
            alt="arrow_back"
          />
        </NavLink>
        <PropertyHeader
          removeProperty={removeProperty}
          propertyState={propertyState}
          onPropertyChanged={onPropertyChanged}
        />
      </section>
      <section className="add-property-filter">
        <PropertyEditPanel
          updateProperty={updateProperty}
          propertyState={propertyState}
          onPropertyChanged={onPropertyChanged}
          property={property}
          updatePropertyPhoto={updatePropertyPhoto}
          handleChangeMedias={handleChangeMedias}
        />
        <PropertyDescriptionBox
          propertyState={propertyState}
          onPropertyChanged={onPropertyChanged}
          property={property}
          updatePropertyPhoto={updatePropertyPhoto}
          updateProperty={updateProperty}
        />
        <PropertyBuildPanel
          propertyState={propertyState}
          onPropertyChanged={onPropertyChanged}
        />
        <FeaturesWrapper
          onPropertyFeatureChanged={onPropertyFeatureChanged}
          propertyState={propertyState}
          onPropertyChanged={onPropertyChanged}
        />
        {property?.privateImages && property?.privateImages?.length > 1 && (
          <PrivateImagesWrapper images={property.privateImages} />
        )}
      </section>
    </>
  );
}
