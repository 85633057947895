export class TranslationsKeys {
  public static navbarMenuLabelProperties = 'mainPage.label.properties';
  public static navbarMenuLabelRequests = 'mainPage.label.requests';
  public static navbarMenuLabelNewsletter = 'mainPage.label.newsletter';
  public static navbarMenuLabelCustomers = 'mainPage.label.customers';
  public static navbarMenuLabelAgents = 'mainPage.label.agents';
  public static navbarMenuLabelPartners = 'mainPage.label.partners';
  public static navbarMenuLabelAnalytics = 'mainPage.label.analytics';
  public static navbarMenuLabelLogout = 'mainPage.label.logout';

  public static partnersPageFullName = 'partnersPage.label.fullName';
  public static partnersPageEmail = 'partnersPage.label.email';
  public static partnersPagePlaceholderName =
    'partnersPage.placeholder.michaelJane';
  public static partnersPagePlaceholderEmail = 'partnersPage.placeholder.email';
  public static partnersPagePlaceholderSearch =
    'partnersPage.placeholder.search';

  public static formsButtonsSignIn = 'forms.buttons.signIn';
}
