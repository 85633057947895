import { AvailableTranslationLang, RuntimeMode } from '@project/enums';

export class AppDetails {
  public static isDevMode: boolean = process.env.NODE_ENV!.includes(
    RuntimeMode.DEV,
  );
  public static isProdMode: boolean = !AppDetails.isDevMode;

  public static apiHost: string = `https://${
    AppDetails.isDevMode ? 'localhost:3001' : 'api.roso.properties'
  }/`;

  public static defaultAppearanceDuration: number = 2500;

  public static fallbackAppLang = AvailableTranslationLang.EN;

  public static cloudflareAnalyticsUrl =
    `https://dash.cloudflare.com/` +
    `7f9d8222402881f369c30dfcf19ed4e2/roso.properties/analytics/traffic`;
  public static googleAnalyticsUrl =
    `https://analytics.google.com/analytics/web/#/` +
    `p431180368/reports/reportinghub?params=_u..nav%3Dmaui`;
}
