import { Checkbox } from './checkbox';
import { RequestFilterItem } from './requests_filter_item';

export function RequestFilter(): JSX.Element {
  return (
    <div className="filter">
      <div className="filter__buttons">
        <RequestFilterItem label="ALL" />
        <RequestFilterItem label="REQUEST" />
        <RequestFilterItem label="SELL" />
        <RequestFilterItem label="VALUATION" />
        <RequestFilterItem label="ASK FOR VIEWING" />
      </div>
      <div className="filter__checkbox">
        <Checkbox />
        <span>Show unanswered</span>
      </div>
    </div>
  );
}
