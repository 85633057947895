import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactPlayer from 'react-player';
import { v4 as uuidv4 } from 'uuid';

import { FileUploader } from '@components/file_uploader/file_uploader';
import { FormInput } from '@components/form_input';

import DragablePhoto from './dragable_photo';
import { FileUploadersSection } from './file_uploaders_section';

type mediaProps = {
  id: string;
  url: string;
};

export const PropertyEditPanel = ({
  propertyState,
  property,
  onPropertyChanged,
  updatePropertyPhoto,
  handleChangeMedias,
}: PropertyPropertyEditPanelProps) => {
  const [medias, setMedias] = useState<mediaProps[]>([]);

  useEffect(() => {
    if (property?.images) {
      const newMedias = property.images.map((url) => ({
        id: uuidv4(),
        url: url,
      }));
      setMedias(newMedias);
    }
  }, [property.images]);

  const handleFileUpload = (file: File | File[]) => {
    updatePropertyPhoto({ images: file, id: property.id });
  };

  const debouncesChangeMedia = useRef(
    debounce((updatedItems) => handleChangeMedias(updatedItems), 500),
  ).current;

  const removeItemById = (id: string) => {
    const updatedMedias = medias.filter((media) => media.id !== id);
    setMedias(updatedMedias);
    debouncesChangeMedia(updatedMedias);
  };

  const moveItem = useCallback(
    (fromIndex: number, toIndex: number) => {
      const updatedItems = [...medias];
      const [movedItem] = updatedItems.splice(fromIndex, 1);
      updatedItems.splice(toIndex, 0, movedItem);
      setMedias(updatedItems);
      debouncesChangeMedia(updatedItems);
    },
    [medias, debouncesChangeMedia],
  );

  return (
    <div className="add-property-filter__panel">
      <div className="add-property-filter__upload">
        <div className="add-property-filter__upload-item">
          {propertyState?.youtubeUrl && (
            <div className="add-property-filter__youtube-video">
              <ReactPlayer url={propertyState.youtubeUrl} />
            </div>
          )}
          <div
            className={`add-property-filter__upload-item-label ${
              propertyState.youtubeUrl && 'haveLink'
            }`}
          >
            <FormInput
              label={
                <div style={{ display: 'flex', gap: '8px' }}>
                  <span>URL:</span>
                  <img src={require('@assets/svg/icons/youtube.svg').default} />
                </div>
              }
              type="text"
              name="youtubeUrl"
              placeholder="youtube.com/watch?v=NgVWmTrwRY8"
              value={propertyState.youtubeUrl || ''}
              onValueChanged={onPropertyChanged}
            />
          </div>
        </div>
        <FileUploadersSection
          medias={medias}
          handleFileUpload={handleFileUpload}
        />
      </div>
      <div className="add-property-filter__photo-feed">
        <div className="add-property-filter__upload-photo">
          <div className="add-property-filter__upload-photo-amount">
            <img src={require('@assets/svg/icons/eye.svg').default} alt="eye" />
            <span>Max amount</span>
            <input type="text" placeholder="10" />
          </div>
          <FileUploader
            multi
            onFileUpload={handleFileUpload}
            children={
              <div className="add-property-filter__upload-photo-button">
                <img
                  src={require('@assets/svg/icons/upload_white.svg').default}
                  alt="upload"
                />
              </div>
            }
          />
        </div>
        {medias.length > 0 && (
          <DndProvider backend={HTML5Backend}>
            <div className="add-property-filter__dnd-photos-box">
              {medias.map((photo, index) => (
                <DragablePhoto
                  key={photo.id}
                  removeItemById={removeItemById}
                  item={photo}
                  index={index}
                  moveItem={moveItem}
                />
              ))}
            </div>
          </DndProvider>
        )}
      </div>
      {/* <button onClick={() => onSubmit(medias)}>click</button> */}
    </div>
  );
};
