import { LocalStorageKey, ReduxActionType } from '@project/enums';

const initialState: ManagersState = {
  credentials: JSON.parse(
    `${localStorage.getItem(LocalStorageKey.CREDENTIALS)}`,
  ),
};

export function managersReducer(
  state: ManagersState = initialState,
  action: DispatchWithPayload<ManagerCredentials>,
): ManagersState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.MANAGERS_SET_DATA:
      return {
        ...state,
        credentials: {
          ...(action.payload as ManagerCredentials),
        },
      };

    case ReduxActionType.MANAGERS_RESET_DATA:
      return {
        ...state,
        credentials: null,
      };
  }
}
export class ManagersActions {
  public static requestSignIn(
    payload: TelegramAuthInitialPayload,
  ): DispatchWithPayload<TelegramAuthInitialPayload> {
    return {
      type: ReduxActionType.MANAGERS_REQUEST_SIGN_IN,
      payload,
    };
  }

  public static requestLogOut(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.MANAGERS_REQUEST_LOGOUT,
    };
  }

  public static setData(
    payload: ManagerCredentials,
  ): DispatchWithPayload<ManagerCredentials> {
    return {
      type: ReduxActionType.MANAGERS_SET_DATA,
      payload,
    };
  }

  public static resetData(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.MANAGERS_RESET_DATA,
    };
  }
}
