import '@scss/index.scss';
import '@translations/module';

import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';

import { MainRouter } from '@routing/testRouter';
import { store } from '@store/store';
import { Router } from 'routing';

ReactDOM.createRoot(document.getElementById('app')!).render(
  <Provider store={store}>
    <Router />
    <Toaster />
  </Provider>,
);
