import { Navigate, Route, Routes } from 'react-router-dom';

import { SignInPage } from '@pages/sign_in.page';
import { AppRoutes } from '@project/enums';

export function defaultRouter(): JSX.Element {
  return (
    <Routes>
      <Route path={AppRoutes.ROOT} element={<SignInPage />} />
      <Route path={AppRoutes.ANY} element={<Navigate to={AppRoutes.ROOT} />} />
    </Routes>
  );
}
