import { Navigate, Route, Routes } from 'react-router-dom';

import { AddNewPropertyPage } from '@pages/add_new_property';
import { AgentsPage } from '@pages/agents.page';
import { AnalyticsPage } from '@pages/analytics.page';
import { EditPropertyPage } from '@pages/edit_property.page';
import { PartnersPage } from '@pages/partners.page';
import { PropertiesPage } from '@pages/properties.page';
import { RequestsPage } from '@pages/requests.page';
import { AppRoutes } from '@project/enums';

export function rootRouter(): JSX.Element {
  return (
    <Routes>
      <Route path={AppRoutes.ANALYTICS} element={<AnalyticsPage />} />
      <Route path={AppRoutes.PARTNERS} element={<PartnersPage />} />
      <Route path={AppRoutes.AGENTS} element={<AgentsPage />} />
      <Route path={AppRoutes.PROPERTIES} element={<PropertiesPage />} />
      <Route path={AppRoutes.REQUESTS} element={<RequestsPage />} />
      <Route
        path={`${AppRoutes.ADD_NEW_PROPERTY}`}
        element={<AddNewPropertyPage />}
      />
      <Route
        path={`${AppRoutes.EDIT_PROPERTY}/:id`}
        element={<EditPropertyPage />}
      />
      <Route
        path={AppRoutes.ANY}
        element={<Navigate to={AppRoutes.ANALYTICS} />}
      />
    </Routes>
  );
}
