// import { DataDropDown } from '@components/data_drop_down';
// import { FormAgentsDropDown } from '@components/form_agents_drop_down';
// import { Navbar } from '@components/navbar';
// import { Preferences } from '@components/prefences';
// import { Switcher } from '@components/switcher';
// import { SwitcherIcon } from '@components/switcher_icon';

export function AgentsPage(): JSX.Element {
  return (
    <>
      AgentsPage
      {/* <section className="admin-navbar">
        <Navbar />
      </section>
      <section className="agents">
        <AddDataForm
          switcher={<Switcher />}
          dropDown={
            <FormAgentsDropDown
              icon={require('@assets/svg/icons/all_agents.svg').default}
            />
          }
        /> */}
      {/* <DataItem
          widget={<SwitcherIcon />}
          docsWidget={<DataDropDown />}
          preferencesWidget={<Preferences />}
        /> */}
      {/* </section> */}
    </>
  );
}
