import { CertificateItemWrapper } from '@components/certificate_item_wrapper';
import { FormAgentsDropDown } from '@components/form_agents_drop_down';
import { FormInput } from '@components/form_input';
import { PropertyEditSwitcher } from '@components/property_edit_switcher';
import { locations, propertyOwnership, propertyTypes } from '@utils/mockData';

export const PropertyBuildPanel = ({
  propertyState,
  onPropertyChanged,
}: PropertyBuildPanelProps) => {
  return (
    <div className="add-property-filter__edit-box">
      <div className="add-property-filter__edit-panel">
        <div className="add-property-filter__edit-place">
          <FormAgentsDropDown
            title="Property Type"
            options={propertyTypes}
            defaultValue={propertyState.type}
            // onRemove={() => onPropertyChanged('type', null)}
            onChange={(selectedOption: DropDownItem) =>
              onPropertyChanged('type', selectedOption.id)
            }
          />
          <FormAgentsDropDown
            title="Location"
            options={locations}
            defaultValue={propertyState.locationId}
            // onRemove={() => onPropertyChanged('locationId', null)}
            onChange={(selectedOption) =>
              onPropertyChanged('locationId', selectedOption.id)
            }
          />
        </div>
        <div className="add-property-filter__edit-area">
          <FormInput
            type="number"
            label="Build area"
            name="buildArea"
            placeholder="Build area"
            icon={
              require('@assets/svg/icons/property_item/build_area.svg').default
            }
            value={propertyState.buildArea || ''}
            onValueChanged={onPropertyChanged}
          />
          <FormInput
            type="number"
            label="Land Area"
            name="landArea"
            placeholder={'Land Area'}
            icon={
              require('@assets/svg/icons/property_item/land_area.svg').default
            }
            value={propertyState.landArea || ''}
            onValueChanged={onPropertyChanged}
          />
          <div className="add-property-filter__edit-area-select">
            <FormAgentsDropDown
              title="Ownership"
              options={propertyOwnership}
              defaultValue={propertyState.ownership}
              // onRemove={() => onPropertyChanged('ownership', null)}
              onChange={(selectedOption) =>
                onPropertyChanged('ownership', selectedOption.id)
              }
            />
          </div>
        </div>
        <div className="add-property-filter__edit-room">
          <FormInput
            type="number"
            label="Bathroom"
            name="bathrooms"
            placeholder={'Bathroom'}
            icon={
              require('@assets/svg/icons/property_item/bathroom.svg').default
            }
            value={propertyState.bathrooms || ''}
            onValueChanged={onPropertyChanged}
          />
          <FormInput
            type="number"
            label="Bedroom"
            name="bedrooms"
            placeholder={'Bedroom'}
            icon={
              require('@assets/svg/icons/property_item/bedroom.svg').default
            }
            value={propertyState.bedrooms || ''}
            onValueChanged={onPropertyChanged}
          />
        </div>
      </div>
      <div className="add-property-filter__certificate">
        <span>Energy certificate:</span>
        <img
          src={require('@assets/svg/icons/arrow_down_black.svg').default}
          alt="arrow"
        />
        <CertificateItemWrapper
          property={propertyState}
          onPropertyChanged={onPropertyChanged}
        />
      </div>
      <div className="add-property-filter__switcher">
        <PropertyEditSwitcher
          label="Swimming pool"
          icon={
            require('@assets/svg/icons/property_item/swimming_pool.svg').default
          }
          checked={propertyState.hasSwimmingPool}
          onChange={(flag: boolean) =>
            onPropertyChanged('hasSwimmingPool', flag)
          }
        />
        <PropertyEditSwitcher
          label="Garage"
          icon={require('@assets/svg/icons/property_item/garage.svg').default}
          checked={propertyState.hasGarage}
          onChange={(flag: boolean) => onPropertyChanged('hasGarage', flag)}
        />
      </div>
    </div>
  );
};
