import { put, call } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { MessageType } from '@project/enums';
import { AppActions } from '@store/items/app';
import { ManagersActions } from '@store/items/managers';
import { StatsActions } from '@store/items/stats';

export function* customerTotalStatsWorker(
  action: DispatchWithPayload<TelegramAuthInitialPayload>,
) {
  yield put(AppActions.lockUI());

  try {
    const customerTotalStats: CustomerTotalStats | ApiMessage = yield call(
      ApiRequests.getCustomersStats,
    );

    if ((customerTotalStats as ApiMessage).message) {
      throw (customerTotalStats as ApiMessage).message;
    }

    yield put(
      StatsActions.setCustomersStats(customerTotalStats as CustomerTotalStats),
    );
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(ManagersActions.resetData());
    }
  }

  yield put(AppActions.unlockUI());
}
