import { ForkEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { ReduxActionType } from '@project/enums';
import {
  logoutManagerWorker,
  signInManagerWorker,
} from '@store/side_effects/managers';
import {
  createNewPartner,
  getAllPartners,
  removePartnerAvatarById,
  removePartnerById,
  updatePartnerById,
} from '@store/side_effects/partners';
import { customerTotalStatsWorker } from '@store/side_effects/stats';

//TODO: path
import { getAllProperties, removePropertyById } from './properties';
import {
  createNewProperty,
  getPropertyId,
  removeProperty,
  updatePropertyById,
  updatePropertyPhotoById,
} from './property';

export function* rootSagaWatcher(): Generator<ForkEffect<void>> {
  //PARTNERS
  yield takeEvery(
    ReduxActionType.PARTNERS_REQUEST_DELETE_PARTNER,
    removePartnerById,
  );

  yield takeEvery(
    ReduxActionType.PARTNER_REQUEST_REMOVE_AVATAR,
    removePartnerAvatarById,
  );

  yield takeEvery(
    ReduxActionType.PARTNERS_REQUEST_CREATE_NEW_PARTNER,
    createNewPartner,
  );

  yield takeLatest(
    ReduxActionType.PARTNERS_REQUEST_UPDATE_PARTNER,
    updatePartnerById,
  );
  yield takeEvery(
    ReduxActionType.PARTNERS_REQUEST_GET_ALL_PARTNERS,
    getAllPartners,
  );
  //MANAGERS
  yield takeEvery(
    ReduxActionType.MANAGERS_REQUEST_SIGN_IN,
    signInManagerWorker,
  );

  yield takeEvery(ReduxActionType.MANAGERS_REQUEST_LOGOUT, logoutManagerWorker);

  //STATS_REQUEST_CUSTOMERS_TOTAL
  yield takeEvery(
    ReduxActionType.STATS_REQUEST_CUSTOMERS_TOTAL,
    customerTotalStatsWorker,
  );

  //PROPERTIES
  yield takeEvery(
    ReduxActionType.PROPERTIES_REQUEST_GET_ALL_PROPERTIES,
    getAllProperties,
  );

  yield takeLatest(
    ReduxActionType.PROPERTIES_UPDATE_PROPERTY,
    updatePropertyById,
  );

  yield takeEvery(
    ReduxActionType.PROPERTIES_REQUEST_DELETE_PROPERTY,
    removePropertyById,
  );
  //PROPERTY
  yield takeEvery(
    ReduxActionType.PROPERTY_REQUEST_CREATE_NEW_PROPERTY,
    createNewProperty,
  );

  yield takeLatest(ReduxActionType.PROPERTY_REQUEST_UPDATE, updatePropertyById);

  yield takeEvery(ReduxActionType.PROPERTY_REQUEST_GET_PROPERTY, getPropertyId);

  yield takeLatest(
    ReduxActionType.PROPERTY_REQUEST_ADD_PHOTO,
    updatePropertyPhotoById,
  );
  yield takeEvery(ReduxActionType.PROPERTY_REQUEST_REMOVE, removeProperty);
}
