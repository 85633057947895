import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Spinner from '@components/spinner';
import { PropertyAction } from '@store/items/property';

import { EditProperty } from '../features/properties/properties_components/edit_property';

const mapState = ({ property }: RootState) => ({
  property: property.property,
});

const mapDispatch = {
  getProperty: PropertyAction.getProperty,
  resetProperty: PropertyAction.resetData,
  updateProperty: PropertyAction.updateProperty,
  updatePropertyPhoto: PropertyAction.addPropertyPhoto,
  removeProperty: PropertyAction.removeProperty,
};

function Component({
  property,
  getProperty,
  resetProperty,
  updateProperty,
  updatePropertyPhoto,
  removeProperty,
}: EditPropertyPageProps): JSX.Element {
  const { id } = useParams();
  const haveId = id && id;
  useEffect(() => {
    if (haveId) {
      getProperty({ id: +haveId });
    }
    return () => {
      resetProperty();
    };
  }, []);

  return (
    <>
      {property ? (
        <EditProperty
          removeProperty={removeProperty}
          updatePropertyPhoto={updatePropertyPhoto}
          updateProperty={updateProperty}
          property={property}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
}

export const EditPropertyPageConnector = connect(mapState, mapDispatch);

export const EditPropertyPage = EditPropertyPageConnector(Component);
