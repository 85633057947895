import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { AppRoutes } from '@project/enums';
import { ManagersActions } from '@store/items/managers';

const mapDispatch = {
  requestLogOut: () => ManagersActions.requestLogOut(),
};

function Component({ requestLogOut }: NavbarProps): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    window.onresize = function (event) {
      setIsOpened(false);
    };
  }, []);

  return (
    <div className="navbar">
      <div className={`navbar-mobile ${isOpened ? 'opened' : ''}`}>
        <NavLink to="/properties" end>
          Properties
        </NavLink>
        <NavLink to="/requests" end>
          Requests
        </NavLink>
        <NavLink to="/newsletter" end>
          Newsletter
        </NavLink>
        <NavLink to="/customers" end>
          Customers
        </NavLink>
        <NavLink to="/agents" end>
          Agents
        </NavLink>
        <NavLink to="/partners" end>
          Partners
        </NavLink>
        <NavLink to="/analytics" end>
          Analytics
        </NavLink>
        <NavLink to={AppRoutes.ROOT} onClick={requestLogOut}>
          Logout
        </NavLink>
      </div>
      <img src={require('../assets/svg/logo.svg').default} alt="logo" />
      <div className="navbar-top">
        <NavLink to="/properties" end className="navbar-item ">
          <img
            src={require('../assets/svg/icons/properties.svg').default}
            alt="properties"
          />
          <span>PROPERTIES</span>
        </NavLink>
        <NavLink to="/requests" end className="navbar-item">
          <img
            src={require('../assets/svg/icons/requests.svg').default}
            alt="requests"
          />
          <span>REQUESTS</span>
        </NavLink>
        <NavLink to="/newsletter" end className="navbar-item">
          <img
            src={require('../assets/svg/icons/newsletter.svg').default}
            alt="newsletter"
          />
          <span>NEWSLETTERS</span>
        </NavLink>
        <NavLink to="/customers" end className="navbar-item">
          <img
            src={require('../assets/svg/icons/customers.svg').default}
            alt="customers"
          />
          <span>CUSTOMERS</span>
        </NavLink>
        <NavLink to="/agents" end className="navbar-item">
          <img
            src={require('../assets/svg/icons/agents.svg').default}
            alt="agents"
          />
          <span>AGENTS</span>
        </NavLink>
        <NavLink to="/partners" end className="navbar-item">
          <img
            src={require('../assets/svg/icons/partners.svg').default}
            alt="partners"
          />
          <span>PARTNERS</span>
        </NavLink>
        <NavLink to="/analytics" end className="navbar-item">
          <img
            src={require('../assets/svg/icons/analytics.svg').default}
            alt="analytics"
          />
          <span>ANALYTICS</span>
        </NavLink>
      </div>
      <NavLink
        to={AppRoutes.ROOT}
        onClick={requestLogOut}
        end
        className="navbar-item"
      >
        <img
          src={require('../assets/svg/icons/logout.svg').default}
          alt="analytics"
        />
        <span>LOGOUT</span>
      </NavLink>
      {isOpened ? (
        <div className="navbar-burger" onClick={() => setIsOpened(false)}>
          <img
            src={require('../assets/svg/icons/burger.svg').default}
            alt="burger"
          />
        </div>
      ) : (
        <div className="navbar-burger" onClick={() => setIsOpened(true)}>
          <img
            src={require('../assets/svg/icons/burger.svg').default}
            alt="burger"
          />
        </div>
      )}
    </div>
  );
}

export const NavbarConnector = connect(null, mapDispatch);

export const Navbar = NavbarConnector(Component);
