import { useState } from 'react';

import { SearchInput } from './search_input';
import { SocialPanel } from './social_panel';
import { SwitcherIcon } from './switcher_icon';
import { UserInfo } from '../features/partners/partners_components/user_info';

export function RequestUserInfo(): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="user-info" onClick={setIsOpened.bind(null, !isOpened)}>
      <img
        src={require('../assets/svg/icons/profile.svg').default}
        alt="profile"
      />
      <span>Lili Aristolivina</span>
      <img src={require('../assets/svg/icons/eye.svg').default} alt="eye" />
      <div className={`user-info__user-layout ${isOpened ? 'opened' : ''} `}>
        <div className="triangle"></div>
        <div className="user-info__user-layout-profile">
          <UserInfo widget={<></>} />
          <SocialPanel />
        </div>
        <SearchInput />
        <div className="agency-item">
          <img
            src={require('../assets/svg/icons/profile.svg').default}
            alt="profile"
          />
          <span>Alamo</span>
          <SwitcherIcon extraClass="request" />
        </div>
        <div className="agency-item">
          <img
            src={require('../assets/svg/icons/profile.svg').default}
            alt="profile"
          />
          <span>Alamo</span>
          <SwitcherIcon extraClass="request" />
        </div>
      </div>
    </div>
  );
}
