import { Checkbox } from './checkbox';

export function PropertyFeaturesItem({
  icon,
  feature,
  onChange,
}: PropertyFeaturesItemProps): JSX.Element {
  return (
    <div className="property-features-item">
      <Checkbox
        checked={feature.flag}
        onChange={(flag: boolean) => onChange({ ...feature, flag })}
      />
      <img src={icon} alt={feature.name} />
      <span>{feature.name}</span>
    </div>
  );
}
