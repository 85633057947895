import { AppError, AppLoader, ReduxActionType } from '@project/enums';

const initialState: AppState = {
  hasUiLocked: false,
  errors: [],
  loaders: [AppLoader.MAIN_LOADING],
};

export function appReducer(
  state: AppState = initialState,
  action: DispatchWithPayload<boolean | AppError | AppLoader>,
): AppState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.APP_LOCK_UI:
      return {
        ...state,
        hasUiLocked: true,
      };

    case ReduxActionType.APP_UNLOCK_UI:
      return {
        ...state,
        hasUiLocked: false,
      };

    case ReduxActionType.APP_SET_ERROR:
      return {
        ...state,
        errors: Array.from(
          new Set([...state.errors, action.payload as AppError]),
        ),
      };

    case ReduxActionType.APP_RESET_ERROR:
      return {
        ...state,
        errors: state.errors.filter(
          (error) => error !== (action.payload as AppError),
        ),
      };

    case ReduxActionType.APP_SET_LOADER:
      return {
        ...state,
        loaders: Array.from(
          new Set([...state.loaders, action.payload as AppLoader]),
        ),
      };

    case ReduxActionType.APP_RESET_LOADER:
      return {
        ...state,
        loaders: state.loaders.filter(
          (loader) => loader !== (action.payload as AppLoader),
        ),
      };
  }
}

export class AppActions {
  public static lockUI(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.APP_LOCK_UI,
    };
  }

  public static unlockUI(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.APP_UNLOCK_UI,
    };
  }

  public static setError(payload: AppError): DispatchWithPayload<AppError> {
    return {
      type: ReduxActionType.APP_SET_ERROR,
      payload,
    };
  }

  public static resetError(payload: AppError): DispatchWithPayload<AppError> {
    return {
      type: ReduxActionType.APP_RESET_ERROR,
      payload,
    };
  }

  public static setLoader(payload: AppLoader): DispatchWithPayload<AppLoader> {
    return {
      type: ReduxActionType.APP_SET_LOADER,
      payload,
    };
  }

  public static resetLoader(
    payload: AppLoader,
  ): DispatchWithPayload<AppLoader> {
    return {
      type: ReduxActionType.APP_RESET_LOADER,
      payload,
    };
  }
}
