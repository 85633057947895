import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { showToast } from '@components/toast';
import { MessageType } from '@project/enums';

export function FileUploader({
  onFileUpload,
  className,
  children,
  multi = false,
}: FileUploaderProps): JSX.Element {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        showToast({
          type: MessageType.ERROR,
          message: 'Something went wrong',
        });
        return;
      }
      const file = multi ? acceptedFiles : acceptedFiles[0];

      try {
        onFileUpload(file);
        showToast({
          type: MessageType.SUCCESS,
          message: 'File upload successful',
        });
      } catch (error) {
        showToast({
          type: MessageType.ERROR,
          message: 'Something went wrong',
        });
      }
    },
    [onFileUpload, multi],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: multi,
  });

  return (
    <div className={className}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {children}
      </div>
    </div>
  );
}
