import { useState } from 'react';

import { EyeIcon } from './eye_icon';

export function PropertySwitcher({
  property,
  onPropertyChanged,
}: CertificateItemWrapperProps) {
  const [isSwitched, setIsSwitched] = useState(property?.isVisible);
  return (
    <div
      className={`properties__switcher ${!isSwitched ? 'switched' : ''}`}
      onClick={() => {
        setIsSwitched(!isSwitched);
        onPropertyChanged && onPropertyChanged('isVisible', !isSwitched);
      }}
    >
      {!isSwitched ? (
        <>
          <img
            src={require('../assets/svg/icons/closed_eye.svg').default}
            alt="closed eye"
          />
          <span>Show from website</span>
        </>
      ) : (
        <>
          <EyeIcon extraClass="eye white" />
          <span>Hide from website</span>
        </>
      )}
    </div>
  );
}
