import { useState } from 'react';

import { certificateTypeOptions } from '@utils/mockData';

import { CertificateItem } from './certificate_item';

export const CertificateItemWrapper = ({
  property,
  onPropertyChanged,
}: CertificateItemWrapperProps) => {
  const [selectedCertificate, setSelectedCertificate] = useState(
    certificateTypeOptions.find(
      (option) => option.id === property.energyCertificate,
    ) || null,
  );

  const handleOptionClick = (option: certificateOptionsType) => {
    setSelectedCertificate(option);
    onPropertyChanged && onPropertyChanged('energyCertificate', option.id);
  };
  return (
    <div className="add-property-filter__certificate-range">
      {certificateTypeOptions.map((option) => (
        <CertificateItem
          key={option.type}
          extraClass={option.type}
          label={option.label}
          selected={option.id === selectedCertificate?.id}
          handleOnClick={() => handleOptionClick(option)}
        />
      ))}
    </div>
  );
};
