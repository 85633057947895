import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { AppDetails } from '@core/app_details';
import { appReducer } from '@store/items/app';
import { managersReducer } from '@store/items/managers';
import { partnersReducer } from '@store/items/partners';
import { statsReducer } from '@store/items/stats';
import { rootSagaWatcher } from '@store/side_effects';

import { propertiesReducer } from './items/properties';
import { propertyReducer } from './items/property';

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const reducer = combineReducers({
  app: appReducer,
  managers: managersReducer,
  stats: statsReducer,
  partners: partnersReducer,
  properties: propertiesReducer,
  property: propertyReducer,
  router: connectRouter(history),
});

const configuredStore = configureStore({
  reducer,
  middleware: [sagaMiddleware, routerMiddleware(history)],
  devTools: AppDetails.isDevMode,
});

sagaMiddleware.run(rootSagaWatcher);

export const store = configuredStore;
