import { useState } from 'react';

import { Checkbox } from '@components/checkbox';
import { Navbar } from '@components/navbar';
import { RequestItem } from '@components/request_item';
import { RequestUserInfo } from '@components/request_user_info';
import { RequestFilter } from '@components/requests_filter';
import { RequestsType } from '@project/enums';

export function RequestsPage(): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <section className="admin-navbar">
        <Navbar />
      </section>
      <section className="requests">
        <RequestFilter />
        <RequestItem label="SERVICES" extraClass={RequestsType.SERVICES} />
        <RequestItem label="REQUEST" extraClass={RequestsType.REQUEST} />
        <RequestItem label="SELL" extraClass={RequestsType.SELL} />
        <RequestItem label="VALUATION" extraClass={RequestsType.VALUATION} />
        <RequestItem
          label="ASK FOR VIEWING"
          extraClass={RequestsType.ASK_FOR_VIEWING}
        />
      </section>
    </>
  );
}
