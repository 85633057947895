import { RouterAccessStatus } from '@project/enums';
import { defaultRouter } from '@routing/default.router';
import { rootRouter } from '@routing/root.router';

export function useRoutes(accessStatus: RouterAccessStatus): JSX.Element {
  switch (accessStatus) {
    default:
    case RouterAccessStatus.UNAUTHORIZED:
      return defaultRouter();

    case RouterAccessStatus.ROOT:
      return rootRouter();
  }
}
