import { useState } from 'react';

export function Checkbox({
  checked,
  onChange,
}: {
  checked?: boolean;
  onChange?: (flag: boolean) => void;
}): JSX.Element {
  const [isAgreed, setIsAgreed] = useState(checked);

  return (
    <div
      className={`checkbox ${isAgreed ? 'checked' : ''}`}
      onClick={() => {
        setIsAgreed(!isAgreed);
        onChange && onChange(!isAgreed);
      }}
    >
      <img
        src={require('../assets/svg/icons/checkbox.svg').default}
        alt="checkbox"
      />
    </div>
  );
}
