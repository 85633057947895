import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from '@components/modal';
import { PropertyFilterType } from '@components/property_filter_type';
import { PropertySwitcher } from '@components/property_switcher';
import { AppRoutes } from '@project/enums';

export const PropertyHeader = ({
  propertyState,
  onPropertyChanged,
  removeProperty,
}: PropertyHeaderProps) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleRemoveProperty = () => {
    setIsModalOpen(false);
    removeProperty({ id: propertyState.id });
    navigate(AppRoutes.PROPERTIES);
  };

  return (
    <div className="control-panel__buttons">
      <PropertySwitcher
        property={propertyState}
        onPropertyChanged={onPropertyChanged}
      />
      <PropertyFilterType
        onPropertyChanged={onPropertyChanged}
        status={propertyState.status}
      />
      <div>
        <img
          onClick={() => handleModalToggle()}
          src={require('@assets/svg/icons/delete.svg').default}
          alt="delete"
        />
      </div>
      {isModalOpen && (
        <Modal onClose={handleModalToggle}>
          <p>Are you sure you want to delete this property?</p>
          <div className="modal-buttons">
            <button
              className="modal-button modal-button--confirm"
              onClick={handleRemoveProperty}
            >
              Yes
            </button>
            <button className="modal-button" onClick={handleModalToggle}>
              No
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
