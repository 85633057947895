import { useState } from 'react';

import { PrivateImage } from './private_image';

export const PrivateImagesWrapper = ({ images }: { images: string[] }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <section className="property-features">
      <div
        className="property-features__label"
        onClick={() => setIsOpened(!isOpened)}
      >
        <img
          className={`${isOpened ? 'opened' : ''}`}
          src={require('@assets/svg/icons/arrow-down.svg').default}
          alt="arrow-down"
        />
        <span>Private Images</span>
      </div>
      <div className={`private-image-container ${isOpened ? 'opened' : ''}`}>
        {images.map((image, index) => (
          <PrivateImage key={index} image={image} />
        ))}
      </div>
    </section>
  );
};
