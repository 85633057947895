export function SocialPanel(): JSX.Element {
  return (
    <div className="data-item__person-social-data">
      <img
        src={require('../assets/svg/icons/telegram.svg').default}
        alt="telegram"
      />
      <img
        src={require('../assets/svg/icons/whatsapp.svg').default}
        alt="whatsapp"
      />
      <img
        src={require('../assets/svg/icons/network.svg').default}
        alt="network"
      />
      <img src={require('../assets/svg/icons/sent.svg').default} alt="sent" />
      {/* <img
        src={require('../assets/svg/icons/delete.svg').default}
        alt="delete"
      /> */}
    </div>
  );
}
