import { ReduxActionType } from '@project/enums';

const initialState: PartnersState = {
  partners: [],
};

export function partnersReducer(
  state: PartnersState = initialState,
  action: DispatchWithPayload<Partner[] | ExactPartner | UpdateExactPartnerDto>,
): PartnersState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.PARTNERS_SET_DATA:
      return {
        ...state,
        partners: action.payload as Partner[],
      };

    case ReduxActionType.CREATE_NEW_PARTNER_SUCCESS:
      return {
        ...state,
        partners: action.payload as Partner[],
      };
    case ReduxActionType.PARTNERS_REQUEST_DELETE_PARTNER:
      return {
        ...state,
        partners: state.partners.filter(
          /*@ts-ignore*/
          (partner) => partner.id !== (action.payload.id as ExactPartner['id']),
        ),
      };
    case ReduxActionType.PARTNER_REQUEST_REMOVE_AVATAR:
      return {
        ...state,
        partners: state.partners.map((partner) => {
          /*@ts-ignore*/
          if (partner.id === action.payload.id) {
            return {
              ...partner,
              avatar: null,
            };
          }
          return partner;
        }),
      };
    case ReduxActionType.PARTNERS_REQUEST_UPDATE_PARTNER:
      {
        const updatedIndex = state.partners.findIndex(
          /*@ts-ignore*/
          (partner) => partner.id === action.payload.id,
        );
        if (updatedIndex !== -1) {
          const updatedPartners = [...state.partners];
          updatedPartners[updatedIndex] = action.payload as Partner;
          return {
            ...state,
            partners: updatedPartners,
          };
        }
      }
      return state;
    case ReduxActionType.PARTNER_SUCCESS_UPDATE:
      return {
        ...state,
        partners: state.partners.map((p) =>
          /*@ts-ignore*/
          p.id === action.payload.id ? action.payload : p,
        ) as Partner[],
      };
    case ReduxActionType.PARTNERS_DELETE_PARTNER_SUCCESS:
      return state;
  }
}

export class PartnerAction {
  public static requestCreateNewPartner(
    payload: CreateNewPartnerDto,
  ): DispatchWithPayload<CreateNewPartnerDto> {
    return {
      type: ReduxActionType.PARTNERS_REQUEST_CREATE_NEW_PARTNER,
      payload,
    };
  }
  public static createNewPartnerSuccess(
    payload: CrateNewPartnerPayload,
  ): DispatchWithPayload<CrateNewPartnerPayload> {
    return {
      type: ReduxActionType.CREATE_NEW_PARTNER_SUCCESS,
      payload,
    };
  }
  public static setPartners(
    payload: Partner[],
  ): DispatchWithPayload<Partner[]> {
    return {
      type: ReduxActionType.PARTNERS_SET_DATA,
      payload,
    };
  }
  public static getPartners(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.PARTNERS_REQUEST_GET_ALL_PARTNERS,
    };
  }
  public static removePartner(
    payload: ExactPartner,
  ): DispatchWithPayload<ExactPartner> {
    return {
      type: ReduxActionType.PARTNERS_REQUEST_DELETE_PARTNER,
      payload,
    };
  }
  public static removeAvatar(
    payload: ExactPartner,
  ): DispatchWithPayload<ExactPartner> {
    return {
      type: ReduxActionType.PARTNER_REQUEST_REMOVE_AVATAR,
      payload,
    };
  }
  public static updatePartner(
    payload: UpdateExactPartnerDto,
  ): DispatchWithPayload<UpdateExactPartnerDto> {
    return {
      type: ReduxActionType.PARTNERS_REQUEST_UPDATE_PARTNER,
      payload,
    };
  }
  public static updatePartnerSuccess(
    payload: UpdateExactPartnerDto,
  ): DispatchWithPayload<UpdateExactPartnerDto> {
    return {
      type: ReduxActionType.PARTNER_SUCCESS_UPDATE,
      payload,
    };
  }
  public static resetData(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.MANAGERS_RESET_DATA,
    };
  }
}
