import _ from 'lodash';
import { useState } from 'react';

import { FormInput } from '@components/form_input';
import { PropertyFeaturesItem } from '@components/property_features_item';

export const FeaturesWrapper = ({
  onPropertyFeatureChanged,
  propertyState,
  onPropertyChanged,
}: FeaturesWrapperProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const propertyFeatures = propertyState?.propertyFeatures || [];

  const chunkedFeatures = _.chunk(
    propertyState?.propertyFeatures,
    Math.ceil(propertyFeatures.length / 2),
  );
  const firstColumn = chunkedFeatures[0];
  const secondColumn = chunkedFeatures[1];
  return (
    <section className="property-features">
      <div
        className="property-features__label"
        onClick={() => setIsOpened(!isOpened)}
      >
        <img
          className={`${isOpened ? 'opened' : ''}`}
          src={require('@assets/svg/icons/arrow-down.svg').default}
          alt="arrow-down"
        />
        <span>Property Features</span>
      </div>
      <div className={`property-features__layout ${isOpened ? 'opened' : ''}`}>
        <FormInput
          type="number"
          label="Year of build"
          placeholder={'Year of build'}
          name="yearOfBuild"
          value={propertyState?.yearOfBuild || ''}
          onValueChanged={onPropertyChanged}
        />
        <div className="property-features__row">
          {firstColumn?.map((feature) => (
            <div key={feature.id} className="property-features__row">
              <PropertyFeaturesItem
                feature={feature}
                onChange={onPropertyFeatureChanged}
                icon={
                  // eslint-disable-next-line max-len
                  require(
                    `@assets/svg/icons/property_features/${feature?.id}.svg`,
                  )
                }
              />
            </div>
          ))}
        </div>
        <div className="property-features__row">
          {secondColumn?.map((feature) => (
            <div key={feature.id} className="property-features__row">
              <PropertyFeaturesItem
                feature={feature}
                onChange={onPropertyFeatureChanged}
                icon={
                  // eslint-disable-next-line max-len
                  require(
                    `@assets/svg/icons/property_features/${feature?.id}.svg`,
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
